export const locale = {
  lang: 'en',
  EVENING: 'NIGHT',
  FULL_DAY: 'ALL DAY',
  MORNING: 'MORNING',
  beforeDueDate: 'BEFORE',
  afterDueDate: 'AFTER',
  REMINDER_OR_LINK: 'MIAU',
  CAMPAIGN_TYPES: {
    CONTINUA: 'XXXXXX',
    PILDORA: 'X',
  },
  GLOBAL: {
    NO: ' No',
    YES: ' Yes',
    DELETED: ' Delete',
  },
  MENU: {
    PANEL: 'Panel',
    CUSTOMER_JOURNEY: 'Customer Journey',
    BEHAVIORAL_PILL: 'Behavioral Pill',
    OPERATIVA: 'Executions',
    CLIENTS: 'Clients',
    INSTANCES: 'Cases',
    OPERATIONS: 'Operations',
    PAYMENT_AGREEMENTS: 'Payment Agreements',
    CAMPAIGNS_MANAGER: 'Campaigns Manager',
    CAMPAIGNS: 'Campaigns',
    PORTFOLIOS: 'Portfolios',
    TEMPLATES: 'Templates',
    SEGMENTS: 'Segments',
    ADMINISTRATION: 'Administration',
    CONFIGURATION: 'Configuration',
    COLLECTOR_PROFILES: 'Collector Profiles',
    BRANDS: 'Brands',
    NOTIFICATION_CHANNELS: 'Notification Channels',
    FEEDBACK_OPTIONS: 'Feedback Options',
    PAYMENT_METHODS: 'Payment Methods',
    USERS: 'Users',
    TAX_ENTITIES: 'Tax Entities',
    SERVICES: 'Services',
    REGIONAL_CONFIGURATION: 'Regional Configuration',
    DATA_SHARING: 'Data Sharing',
    PHASES: 'Phases',
  },
  DATA: {
    REPORTS: 'REPORTSSS',
    CHANNEL: {
      EMAIL: 'Email',
      SMS: 'SMS',
      PHONE: 'Telephone',
      CERTIFIED_EMAIL: 'Certified SMS',
      CERTIFIED_SMS: 'Certified Email',
    },
    COMMON: {
      GENDER: {
        MALE: '♂ Male',
        FEMALE: '♀ Female',
        NOT_KNOWN: 'Prefer not to say',
        NON_BINARY: 'Non-binary',
        UNKNOWN: 'Unknown',
      },
      COUNTRIES: {
        ES: 'Spanish',
        ES_MX: 'Spanish (Mexico)',
        EN: 'English',
        CA: 'Catalan',
        CA_ES_VALENCIA: 'Valencian',
        FR: 'French',
        NL: 'Dutch',
        PT: 'Portuguese',
        DE: 'German',
        MX: 'Spanish (Mexico)',
        GB: 'English',
        CAT: 'Catalan',
        IT: 'Italian',
      },
      TIME_OF_WEEK: {
        FULL_DAY: 'the day',
        EVENING: 'the evening',
        MORNING: 'the morning',
      },
      DAYS_OF_WEEK: {
        MONDAY: 'Monday',
        TUESDAY: 'Tuesday',
        WEDNESDAY: 'Wednesday',
        THURSDAY: 'Thursday',
        FRIDAY: 'Friday',
        SATURDAY: 'Saturday',
        SUNDAY: 'Sunday',
      },
      LAST_DAY: 'Last',
      CHANNEL: {
        EMAIL: 'Email',
        PHONE: 'Telephone',
        SEND_EMAIL: 'Send Email',
        SEND_SMS: 'Send SMS',
        SEND_CERTIFIED_EMAIL: 'Send certified Email',
        SEND_CERTIFIED_SMS: 'Send certified SMS',
      },
      SECTOR: {
        ENERGIA: 'Energy',
        FINANZAS: 'Finance',
        SEGUROS: 'Insurance',
        TELECOMUNICACIONES: 'Telecommunications',
        GIMNASIOS: 'Gyms',
        TRASTEROS: 'Storage Units',
        UTILITIES: 'Utilities',
      },
      PROFILE: {
        HELLO: 'Hello,',
      },
      PAGINATOR: '{first} - {last} of {totalRecords} results',
      TREATMENT: {
        TU: 'You',
        USTED: 'Formal You',
      },
      TONE: {
        BAJO: 'Low',
        MEDIO: 'Medium',
        ALTO: 'High',
      },
      TARGET: {
        AMISTOSO: 'Friendly',
        RECORDATORIO: 'Reminder',
        AVISO_SUSPENSION_SERVICIOS: 'Service Suspension Notice',
        CONFIRMACION_SUSPENSION_SERVICIOS: 'Service Suspension Confirmation',
        AVISO_BAJA_SERVICIO: 'Definitive Service Termination Notice',
        CONFIRMACION_BAJA_SERVICIO: 'Definitive Service Termination Confirmation',
        AVISO_FIN_CONTRATO: 'Contract Termination Notice',
        CONFIRMACION_FIN_CONTRATO: 'Contract Termination Confirmation',
        AVISO_INCLUSION_BBDD: 'Notice of Inclusion in Public Debtor Databases (asnef/badexcug)',
        AVISO_INCIDENCIAS_LEGALES: 'Notice of Possible Legal Incidents',
        CONFIRMACION_INCIDENCIAS_LEGALES: 'Confirmation of Legal Incidents',
      },
      PLACEHOLDER: {
        short_name: '%short_name%',
        long_name: '%long_name%',
        tax_entity_society: '%tax_entity_society%',
        tax_entity_vat_number: '%tax_entity_vat_number%',
        case_amount: '%case_amount%',
        send_date: '%send_date%',
        brand: '%brand%',
        pending_operations: '%pending_operations%',
      },
      HUSO: {
        LABEL: 'Timezone',
        BUTTON_TEXT: 'Assign timezone',
        HEADER: 'Select Timezone',
        TOOLTIP:
          'Adjust the timezone to view the exact payment times in other countries. For example, if you are in Spain, you can see what time payments were made in Mexico or Peru.',
      },
      GENERIC: {
        NOT_FOUND: 'Not found',
        IS: 'is',
        MORE: 'more',
        RESET_FILTERS: 'Reset the filters',
      },
      OWNER: {
        BUSINESS: 'Business',
        PRIVATE: 'Private',
      },
      NAMES: {
        CAMPAIGN: 'campaign',
        PILL: 'pill',
        COLLECTOR: 'collector profile',
      },
      ACTIONS: {
        PUBLISH: 'Publish ',
        DRAFT: 'Archive ',
        PAUSED: 'Pause ',
        DRAFT_INFO: 'No status changes available',
        CANCEL: 'Cancel',
        DELETE: 'Delete',
        SAVE: 'Save',
        SEND: 'Send',
        DOWNLOAD: 'Download',
        CLONE: 'Clone',
        SEARCH: 'Search',
        FILTERS: 'Filters',
        SELECT_PLACEHOLDER: 'Select {{placeholder}}',
        APPLY_FILTERS: 'Apply filters',
        QUESTION: '¿Do you want to proceed with this action?',
      },
      USER_MENU: {
        PROFILE: 'Profile',
        LANGUAGE: 'Language',
        LOG_OUT: 'Log out',
      },
      SEARCH: {
        SEARCH_BUTTON: 'Search',
        RESET_BUTTON: 'Clear',
        PLACEHOLDER_DROPDOWN: 'Select an option',
      },
      FORM_BUTTONS: {
        SAVE: 'Save',
        CANCEL: 'Cancel',
        SEND: 'Send',
        CONFIRM: 'Confirm',
        CLONE: 'Clone',
        EDIT: 'Edit',
      },
      TOAST: {
        SUCCESS: 'Success',
        ERROR: 'Error',
        SUCCESS_EMAIL: 'Reminder sent successfully to {{email}}',
        SUCCESS_SMS: 'Reminder sent successfully to {{sms}}',
        NO_NOTIFICATED: 'Notification not sent',
        CONFIRMED: 'Confirmed!',
        CONFIRMED_EXECUTION: 'Action executed properly',
        SCENARIOS: {
          LIST: {
            ERROR: 'Error listing items',
          },
        },
        COPIED: 'Copied!',
        COPIED_TEXT: 'Text {{text}} copied successfully',
        COPY_ID: 'Copy Id',
      },
      TIME: {
        TODAY: 'Today',
        YESTERDAY: 'Yesterday',
        LAST_WEEK: 'Last week',
        LAST_MONTH: 'Last month',
        LAST_YEAR: 'Last year',
        DAYS: 'days',
        DAY: 'day',
        MONTH: 'month',
        MONTHS: 'months',
      },
      GENDERS: {
        MALE: 'Male',
        FEMALE: 'Female',
        NOT_KNOW: 'Prefer not to say',
        NOT_BINARY: 'Non-binary',
      },
      MONTHS: {
        JANUARY: 'January',
        FEBRUARY: 'February',
        MARCH: 'March',
        APRIL: 'April',
        MAY: 'May',
        JUNE: 'June',
        JULY: 'July',
        AUGUST: 'August',
        SEPTEMBER: 'September',
        OCTOBER: 'October',
        NOVEMBER: 'November',
        DECEMBER: 'December',
      },
      WORKFLOW_TYPE: {
        PILL: 'Píldora',
        DRIP: 'Continua',
      },
      LANGUAGES: {
        ES: 'Spanish',
        EN: 'English',
        CA: 'Catalan',
        FR: 'French',
        CV: 'Valencian',
      },
      PAYMENT_METHODS: {
        CASH: 'Cash',
        BANK_TRANSFER: 'Bank transfer',
        OTHER: 'Other',
        DIRECT_DEBIT: 'Direct debit',
        CREDIT_CARD: 'Credit card',
        PAYMEFY: 'Paymefy',
        UNKNOWN: 'Unknown method',
      },
      STATUS: {
        on_hold: 'On hold',
        pending: 'Pending',
        in_allegations: 'In allegations',
        charged: 'Charged',
        agreement: 'Payment agreement',
        discarded: 'Discarded',
        opened: 'Open',
        open: 'Open',
        closed: 'Closed',
        created: 'Created',
        close: 'Closed',
        worked: 'Worked',
        silenced: 'Silenced',
        queued: 'Queued',
        generating: 'Generating',
        generated: 'Generated',
        failed: 'Failed',
        archived: 'Archived',
        draft: 'Draft',
        committed: 'Final load',
        processing: 'Processing',
        committing: 'Loading',
        invalid_file: 'Invalid file',
        valid: 'Valid',
        not_valid: 'Invalid',
        paused: 'Paused',
        published: 'Published',
        unpublished: 'Archived',
        running: 'Active',
        expired: 'Expired',
        proposal: 'Proposal',
        signed: 'Signed',
        fulfilled: 'Completed',
        broken: 'Broken',
        started: 'Started',
        notifying: 'Notifying',
        blacklisted: 'Blacklisted',
      },
      CHARACTERS: 'Characters',
      FORM: {
        PLACEHOLDER_RANGE: 'Start date - End date',
        ERROR: {
          MIN_LENGTH: 'Minimum length required is {{requiredLength}} characters.',
          MAX_LENGTH: 'Maximum length allowed is {{requiredLength}} characters.',
          MIN: 'Minimum value allowed is {{min}}.',
          MAX: 'Maximum value allowed is {{max}}.',
          MAX_CHAR: 'Exceeded the maximum limit of {{maxChar}} characters.',
          MIN_ELEMENTS_IN_ARRAY: 'At least {{amount}} elements are required.',
          REQUIRED: 'This field is required',
          EMAIL: 'Enter a valid email address',
          PATTERN: 'Invalid format',
          EMPTY_VALUE: 'This field cannot be empty',
          INVALID_DNI_NIF: 'Invalid DNI or NIF format',
          INVALID_CP: 'Invalid postal code format',
          MAX_SAFE_INTEGER: 'Maximum accepted number exceeded',
          INVALID_ALPHA_DIGIT: 'Invalid alias format',
          IBAN: 'Invalid IBAN format',
          BIC: 'Invalid BIC format',
          FUTURE_DATE: 'The date must be in the future',
          MAX_IMPORT_ON_TOTAL_AMOUNT: 'The amount cannot exceed the total',
          STARTS_WITH_AND_ENDS_WITH: 'The field must start and end with {{char}}',
          RANGE_INVALID: 'The end field cannot be greater than the start field',
          RANGE_NOT_COMPLETED: 'Both Start and End fields must be filled or empty',
          ALL_OPTIONS_SELECTED: 'All options cannot be selected',
          SELECT_RANGE_DATES: 'Select a valid range date',
          PHONE_CODE: 'The phone number is not valid, example: +34000000000',
          HTTP: 'The URL must start with http:// or https://',
          URL_NOT_VALID: 'The URL is not valid, e.g., https://example.com',
          ALIAS: 'The ALIAS contains invalid characters',
        },
      },
    },
    WEEK_DAYS: {
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      SUNDAY: 'Sunday',
    },
    TRANSLATOR: {
      SELECT: 'Choose your language',
    },
    MENU: {
      SIDE: {
        DASHBOARD: 'dashboard',
        ANALYTICS: 'Analytics',
        CRM: {
          CRM: 'CRM',
          IMPORT_LINES: 'Import list',
          EXPEDIENTS: 'Operations',
          CUSTOMERS: 'Customers',
          PAYMENT_AGREEMENT: 'Payment agreements',
          SEGMENTS: 'Segments',
          PORTFOLIOS: 'Portfolios',
        },
        CAMPAIGN_MANAGER: {
          CAMPAIGN_MANAGER: 'Campaign Manager',
          CAMPAIGNS: 'Campaigns',
          COLLECTION_CYCLE: 'Collection cycles',
          WORKFLOW: 'Automated flow',
          TEMPLATES: 'Templates',
        },
        ADMINISTRATION: {
          ADMINISTRATION: 'Administration',
          INVOICES: 'Invoices',
          USERS: 'Users',
          COLLECTORS: 'Collectors',
          REGIONAL_CONFIG: 'Regional configuration',
          PAYMENT_SOLUTION: 'Payment solutions',
          BRANDS: 'Brands',
          TAX_ENTITIES: 'Tax entities',
          PAYMENT_METHODS: 'Payment methods',
          NOTIFICATION_CHANNELS: 'Notification channels',
          FEEDBACK_OPTIONS: 'Feedback options',
          SERVICES: 'Services',
          EVENT_HOOK: 'Tracking methods',
        },
      },
    },
    ADMIN: {
      TITLE: 'Administration',
      MODULES: {
        COPIES: {
          SEARCH: 'Search',
          GENERATE_IA: 'Generate with IA',
          WANT_DELETE: 'Do you want to delete {{name}}?',
          TEMPLATE_DELETE: 'Delete Template',
          TEMPLATE_DELETED: 'Template deleted successfully',
          CLONED: 'Template cloned successfully',
          CLONED_ERROR: 'Could not clone the template',
          CLONED_NAME: 'Add a name for the new template:',
          CLONED_HEADER: 'Clone the template',
          CLONED_NAME_NEW: 'Name:',
          NEW_TEMPLATE: 'New Template',
          ALL_CHANNELS: 'All Channels',
          SAVE_CHANGES: 'Save changes',
          SMS: 'SMS',
          EMAIL: 'Email',
          CERTIFIED_SMS: 'Certified SMS',
          CERTIFIED_EMAIL: 'Certified Email',
          NAME: 'Name',
          CHANNEL: 'Channel',
          LANGUAGE: 'Language',
          VERSION: 'Version',
          LAST_UPDATE: 'Last Update',
          REMINDER: 'Reminder',
          PAYMENT_AGREEMENT: 'Payment Agreement',
          ACTIONS: 'Actions',
          NO_TEMPLATES: 'No templates to display',
          INFO_SMS: 'Info SMS',
          INFO_TEMPLATE: 'Info about templates',
          TOOLTIP_MUST_FIELDS: 'Please fill all the required fields',
          CREATE_TEMPLATE: 'Create template',
          AUTOCOMPLETE_GPT: 'Autocomplete with Chat GPT',
          GENERATING_TEMPLATE: 'Generating template...',
          CLEAN: 'Clean',
          SMS_CERTIFIED_INFO: {
            ICON: "To properly configure your notifications, it is essential to define the type of certified SMS you wish to send. Note that each type of SMS will include the mention at the end of the SMS: '(CERTIFIED SMS)' and the paylink.",
            COUNTER_INFO: 'Our counter already deducts these characters.',
            LENGTH_AND_COST:
              'Regarding length and cost control, we offer the following types of certified SMS:',
            TYPES: {
              MINI: 'Mini (108 characters)',
              STANDARD: 'Standard (560 characters)',
              TWO_SMS: '2 Certified SMS (1,784 characters)',
              THREE_SMS: '3 Certified SMS (2,396 characters)',
            },
            SELECTION_INSTRUCTIONS:
              'Select the desired length and fill in the text field with the content and placeholders you wish to use. Remember that the number of characters affects the cost and effectiveness of your communication.',
            RECOMMENDATION:
              'Choose the type of certified SMS that best suits your message and budget needs.',
          },
          TOOLTIP_ICON:
            'Remember that the maximum length of an SMS is <b>160 characters</b>, while paylinks take up approximately 35 characters at the end of the text. To avoid doubling the cost of the SMS, it is important to keep the text below <b>125 characters</b>.',
          TOOLTIP_RECOMMENDATION:
            'To better control SMS expenses, we recommend <b>using placeholders sparingly</b> in this channel. The content of the message may vary, which could result in sending two SMS instead of one if the character limit is exceeded.',
          FORM: {
            SECTOR: 'Sector',
            CASE: 'Subject',
            TEXT: 'Text',
            TEXT_BUTTON: `Button's text`,
            CLIENT_TREATMENT: 'Customer Treatment',
            GOAL_MSG: 'Message Objective',
            INTENSITY_MSG: 'Message Intensity',
            VARIABLES: 'Variables',
            OP_ADVANCED: 'Advanced operations',
            NUMB_CHARACTERS: 'Number of characters',
            NAME: 'Name',
            PRE_HEADER: 'Pre header',
            LANGUAGE: 'Languages',
            CHANNEL: 'Channel',
            ALIAS: 'Alias',
            TEMPLATE_MAIL: 'Template Mail ID',
            TOOLTIP_PRE: 'Max. 125 characters',
            TOOLTIP_ALIAS:
              'The ALIAS field is optional and is used to assign a unique identifier to a template of any channel.\n' +
              '\n' +
              '    The characters that are supported are:\n' +
              '    a b c d e f g h i j k l m n o p q r s t u v w x y z A B C D E F G H I J K L M N O P Q R S T U V W X Y Z 0 1 2 3 4 5 6 7 8 9 - _ . ~.\n' +
              '\n' +
              '    No spaces allowed',
          },
        },
        BRAND: {
          TITLE: 'Brands',
          PAGES: {
            ADD: {
              TITLE: 'Create brand',
            },
            EDIT: {
              TITLE: 'Edit brand',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                LOGO: 'LOGO',
                NAME: 'NAME',
                COLLECTORS: 'COLLECTION PROFILES',
                ACTIONS: 'ACTIONS',
              },
              ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
              },
              MORE: {
                NO_RESULTS: 'No brands to show',
              },
            },
          },
        },
        USERS: {
          TITLE: 'Users',
          PAGES: {
            ADD: {
              TITLE: 'Create user',
            },
            EDIT: {
              TITLE: 'Edit user',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NAME',
                SURNAME: 'LAST NAME',
                ROLE: 'ROLE',
                EMAIL: 'EMAIL',
                COLLECTORS: 'COLLECTION PROFILES',
                ACTIONS: 'ACTIONS',
                LANGUAGE: 'LANGUAGE',
              },
              ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
              },
              MORE: {
                NO_RESULTS: 'No users to show',
              },
            },
          },
        },
        PORTFOLIOS: {
          TITLE: 'Portfolios',
          FORM: {
            NAME: 'Name',
            ALIAS: 'Alias',
            SEARCH: 'Search',
          },
          MESSAGE: {
            PORTFOLIO_CREATED: 'Portfolio created successfully',
            PORTFOLIO_UPDATED: 'Portfolio updated successfully',
            PORTFOLIO_DELETED: 'Portfolio deleted successfully',
            SUCCESS: 'Success',
            WANT_DELETE: 'Do you want to delete {{name}}?',
            DELETE_PORTFOLIO: 'Delete portfolio',
            YES: 'Yes',
            NO: 'No',
          },
          PAGES: {
            ADD: {
              TITLE: 'Create portfolio',
            },
            EDIT: {
              TITLE: 'Edit portfolio',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NAME',
                ALIAS: 'ALIAS',
                ACTIONS: 'ACTIONS',
              },
              ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
                CREATE: 'Create portfolio',
              },
              MORE: {
                NO_RESULTS: 'No portfolios to show',
              },
            },
          },
        },
        TAX_ENTITY: {
          TITLE: 'Tax Entities',
          PAGES: {
            ADD: {
              TITLE: 'Create Tax Entity',
            },
            EDIT: {
              TITLE: 'Edit Tax Entity',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                SOCIETY: 'CORPORATE NAME',
                NIF: 'NIF',
                COLLECTORS: 'ASSOCIATED PAYMENT PROFILES',
                ACTIONS: 'ACTIONS',
              },
              ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
              },
              MORE: {
                NO_RESULTS: 'No Tax Entities to display',
              },
            },
          },
        },
        SERVICE: {
          TITLE: 'Services',
          CATEGORY: {
            advertising: 'Advertising',
            energy: 'Energy',
            financial_services: 'Financial Services',
            insurance: 'Insurance',
            lawyers: 'Lawyers',
            others: 'Others',
            real_estate_services: 'Real Estate Services',
            telecommunications: 'Telecommunications',
            training: 'Training',
          },
          PAGES: {
            ADD: {
              TITLE: 'Create Service',
            },
            EDIT: {
              TITLE: 'Edit Service',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NAME',
                CATEGORY: 'CATEGORY',
                COLLECTORS: 'ASSOCIATED PAYMENT PROFILES',
                ACTIONS: 'ACTIONS',
              },
              ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
              },
              MORE: {
                NO_RESULTS: 'No Services to display',
              },
            },
          },
        },
        PARTIAL_PAYMENT_CONFIGS: {
          TITLE: 'Payment Solutions',
          PAGES: {
            ADD: {
              TITLE: 'Create Regional Configuration',
            },
            EDIT: {
              TITLE: 'Edit Regional Configuration',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NAME',
                COLLECTORS: 'ASSOCIATED PAYMENT PROFILES',
                ACTIONS: 'ACTIONS',
              },
              ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
              },
              MORE: {
                NO_COLLECTORS: 'No associated payment profiles',
                NO_RESULTS: 'No Partial Payment Configurations to display',
              },
            },
          },
        },
        EVENT_HOOKS: {
          TITLE: 'Data Sharing',
          PAGES: {
            ADD: {
              TITLE: 'Create Tracking Method',
            },
            EDIT: {
              TITLE: 'Edit Tracking Method',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                TYPE: 'TYPE',
                URL: 'TRACKING',
                COLLECTORS: 'ASSOCIATED PAYMENT PROFILES',
                ACTIONS: 'ACTIONS',
                CONTEXT: 'CONTEXTS',
              },
              ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
              },
              MORE: {
                NO_COLLECTORS: 'No associated payment profiles',
                NO_RESULTS: 'No Tracking Methods to display',
              },
            },
          },
        },
        REGIONAL_CONFIGS: {
          TITLE: 'Regional Configuration',
          PAGES: {
            ADD: {
              TITLE: 'Create',
            },
            EDIT: {
              TITLE: 'Edit',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NAME',
                COLLECTORS: 'ASSOCIATED PAYMENT PROFILES',
                ACTIONS: 'ACTIONS',
              },
              ACTIONS: {
                CREATE: 'Regional Configuration',
                EDIT: 'Edit',
                DELETE: 'Delete',
              },
              MORE: {
                NO_COLLECTORS: 'No associated payment profiles',
                NO_RESULTS: 'No Regional Configurations to display',
              },
            },
          },
        },
        PAYMENT_METHODS: {
          TITLE: 'Payment Methods',
          TYPES: {
            RedsysPaymentGateway: 'Redsys',
            PaycometPaymentGateway: 'Paycomet',
            IngenicoPaymentGateway: 'Ingenico',
            RedsysBizumPaymentGateway: 'Bizum (Redsys)',
            PaycometMbwayPaymentGateway: 'MBWAY (Paycomet)',
            PaycometBizumPaymentGateway: 'Bizum (Paycomet)',
            PaycometMultibancoSIBSPaymentGateway: 'Paycomet (Multibanco)',
            InespayPaymentGateway: 'Inespay',
            MoneiBizumPaymentGateway: 'Bizum (Monei)',
            StripePaymentGateway: 'Stripe',
          },
          PAGES: {
            ADD: {
              TITLE: 'Create',
            },
            EDIT: {
              TITLE: 'Edit',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NAME',
                TYPE: 'TYPE',
                DESCRIPTION: 'DESCRIPTION',
                COLLECTORS: 'ASSOCIATED PAYMENT PROFILES',
                ACTIONS: 'ACTIONS',
              },
              ACTIONS: {
                CREATE: 'Add',
                EDIT: 'Edit',
                DELETE: 'Delete',
              },
              MORE: {
                NO_COLLECTORS: 'No associated payment profiles',
                NO_RESULTS: 'No Payment Methods to display',
              },
            },
          },
        },
        COLLECTORS: {
          TITLE: 'Payment Profiles',
          PAGES: {},
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NAME',
                ALIAS: 'ALIAS',
                ACTIONS: 'ACTIONS',
              },
              ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
                CREATE: 'Payment Profile',
              },
              MORE: {
                NO_RESULTS: 'No Payment Profiles to display',
              },
            },
          },
        },
        CHANNELS: {
          TITLE: 'Notification Channels',
          ELEMENTS: {
            EmailChannelConfig: 'Email',
            SmsChannelConfig: 'SMS',
            CertifiedSmsChannelConfig: 'Certified SMS',
            CertifiedEmailChannelConfig: 'Certified Email',
          },
          PAGES: {
            ADD: {
              TITLE: 'Create',
            },
            EDIT: {
              TITLE: 'Edit',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NAME',
                TYPE: 'TYPE',
                COLLECTORS: 'ASSOCIATED PAYMENT PROFILES',
                ACTIONS: 'ACTIONS',
              },
              ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
                CREATE: 'Payment Profile',
              },
              MORE: {
                NO_RESULTS: 'No Notification Channels to display',
              },
            },
          },
        },
        FEEDBACK: {
          TITLE: 'Feedback Options',
          ELEMENTS: {},
          PAGES: {
            ADD: {
              TITLE: 'Create',
            },
            EDIT: {
              TITLE: 'Edit',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NAME',
                CATEGORY: 'CATEGORY',
                TRANSLATE: 'TRANSLATIONS',
                COLLECTORS: 'ASSOCIATED PAYMENT PROFILES',
                ACTIONS: 'ACTIONS',
              },
              ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
                CREATE: 'Payment Profile',
              },
              MORE: {
                NO_RESULTS: 'No Feedback Options to display',
              },
            },
          },
        },
      },
    },
    MODULES: {
      BEHAVIORAL_PILL: {
        TITLE: 'Behavioral pill',
        MESSAGES: {
          NO_DATA: 'No data',
          COMPARE_LAST_MONTH: 'Compared with {{recoveredLastMonth}} from the previous month',
          COMPARE_NO_DATA: 'Compared with - No data - from the previous month',
          RECOVERY: 'Recovery of {{ currentMonth }}',
          ABANDONED_EMAIL: 'Abandoned email',
          PILL: 'Pill',
          PILL_ADD: '+ Add pill',
          ABANDONED_GANGWAY: 'Abandoned payment gateway',
          ABANDONED_PAYMENT_PAGE: 'Abandoned payment page',
          SELECT_PILL: 'Choose a pill to assign',
          SELECT_PILL_ASSIGN: 'Select the pill to assign',
          ASSIGN_PILL: 'Assign pill',
          SHOW_PILL: 'Show pill',
          DELETE_CONFIRMATION: 'Do you want to delete the pill {{pillName}}?',
          DELETE_HEADER: 'Delete Partial Payment',
          ACCEPT_DELETE: 'Delete pill',
          REJECT_DELETE: 'Cancel',
          ACTION_FAILED: 'Action could not be completed',
          NO_SELECTED: 'Pill not selected',
        },
        TOOLTIP: {
          ADD_PILL_SELECTED: 'Select the pill to assign',
          ABANDONED_EMAIL:
            'The pill is sent to users who did not click the payment button 30 minutes after opening the email.',
          ABANDONED_PAYMENT_PAGE:
            'The pill is sent to users who did not click to pay 10 minutes after visiting the payment page.',
          ABANDONED_GANGWAY:
            'The pill is sent to users who did not complete the payment 5 minutes after entering the payment gateway. ',
        },
      },
      CUSTOMERS: {
        TITLE: 'Customers',
        ELEMENTS: {},
        MODAL: {
          DELETE_CONFIRM_HEADER: '<p class="font-normal">Delete <b>{{count}}</b> customers</p>',
          DELETE_CONFIRM_MESSAGE: 'Are you sure you want to delete {{count}} customers?',
          DELETE_SUCCESS: '{{count}} customers deleted successfully',
          DELETE_FAILED: 'An error occurred while deleting {{count}} customers',
          DELETE_SINGLE_CONFIRM_HEADER:
            '<p class="font-normal">Delete customer: <b>{{longName}} ({{externalId}})</b></p>',
          DELETE_SINGLE_CONFIRM_MESSAGE: 'Are you sure you want to delete {{longName}}?',
          DELETE_SINGLE_SUCCESS: '{{longName}} deleted successfully',
          DELETE_SINGLE_FAILED: 'An error occurred while deleting {{longName}}',
          REJECT_LABEL: 'No',
          ACCEPT_LABEL: 'Yes',
          SELECT_ALL: ' Select all customers',
          DELETE_SELECTION: 'Delete selection',
        },
        PAGES: {
          SECTIONS: {
            INFO: 'Information',
            CONTACTS: 'Contacts',
            EMAILS: 'Emails',
            PHONE_LIST: 'Phone List',
            EMAIL_LIST: 'Email List',
            NO_PHONE_CONTACTS: 'This user has no phone contacts',
            NO_EMAIL_CONTACTS: 'This user has no email contacts',
            COPY_TEXT: 'Copy text',
            NOTIFYING: 'Notifying',
            BLACKLISTED: 'Blacklisted',
            LOCALIZATION: 'Localization',
            TOOLTIP_EDIT_INFO: 'Edit customer information',
            TOOLTIP_CREATE_CONTACT: 'Create contact',
            TOOLTIP_EDIT_CONTACT: 'Edit contact',
            TOOLTIP_EDIT_LOCALIZATION: 'Edit localization',
            TOOLTIP_COPY: 'Copy text',
            NAME: 'Name',
            DNI: 'DNI',
            OWNERSHIP: 'Ownership',
            BIRTH_DATE: 'Birth Date',
            GENDER: 'Gender',
            CONTACT_LANGUAGE: 'Contact Language',
            BUSINESS: 'Business',
            PRIVATE: 'Private',
            COPIED: 'Copied',
            COPIED_SUCCESSFULLY: 'Text copied successfully',
            ADDRESS: 'Address',
            ZIP_CODE: 'Zip Code',
            CITY: 'City',
            COUNTRY: 'Country',
            TOAST_CONTACT: 'Contact created',
            TOAST_LIST_CONTACT: 'List of contacts updated',
            TOAST_LOCALIZATION: '{{name}}´s localization updated! ',
            FORMS: {
              TITLE_INFO: 'Edit Information',
              TITLE_ADD_NEW_CONTACT: 'Add new contact',
              TITLE_EDIT_CONTACT: 'Edit contacts',
              TITLE_EDIT_LOCALIZATION: 'Edit contacts',
              ID_CLIENT: 'Id Client',
              SHORT_NAME: 'Short name',
              FULL_NAME: 'Full name',
              NIE: 'DNI/NIE/CIF',
              OWNERSHIP: 'DNI/NIE/CIF',
              BIRTH_DATE: 'Birthdate',
              CONTACT_LANGUAGE: 'Contact Language',
              GENDER: 'Gender',
              NEW_EMAIL: 'New email',
              EDIT: 'Edit',
              TYPE: 'Type',
              NEW_TELEPHONE: 'New phone number',
              CREATE_CONTACT: 'Create Contact',
              DIRECTION: 'Address',
              ZIP_CODE: 'Zip code',
              TOWN: 'Town',
              COUNTRY: 'Country',
            },
          },
          LIST: {
            TITLE: 'Customer List',
          },
          CUSTOMER_LIST: {
            TITLE: 'Customer List',
            NEW: 'New Customer',
            EDIT: 'Edit Customer',
            DELETE_BUTTON: 'Delete {{selected}}',
            FORM: {
              NIF: 'DNI/NIE/NIF',
              EXTERNAL_ID: 'CUSTOMER ID',
              COLLECTOR: 'COLLECTION PROFILE',
              SEARCH: 'SEARCH',
              NO_EXIST_CUSTOMERS: 'There is no customers for this search',
              SEARCH_TOOLTIP: `Search among Customer ID, Full Name, DNI, and contact details (phone and email) for similar results.

        You can also search for multiple items separated by commas.

        Example: María Garcia, Antonio Rodriguez, Carmen Gonzalez`,
            },
            TABLE: {
              HEADERS: {
                NAME: 'NAME',
                DNI: 'DNI/NIE/NIF',
                EXTERNAL_ID: 'CUSTOMER ID',
                COLLECTOR: 'COLLECTION PROFILE',
                TOTAL_AMOUNT: 'TOTAL AMOUNT',
                PENDING_AMOUNT: 'PENDING AMOUNT',
                DATE: 'LAST UPDATED',
                ACTIONS: 'ACTIONS',
              },
              MORE: {
                NO_RESULTS: 'No customers to show',
              },
            },
          },
          CUSTOMER_FILE: {
            TITLE: 'Customer Details',
            SECTIONS: {
              SUPERIOR_INFO: {
                TOTAL_AMOUNT: 'Original Amount',
                NO_PAYMENT_PENDING: '✔ No pending payment',
                PENDING_AMOUNT_TOOLTIP:
                  'Total sum of the outstanding amounts for the operations associated with this client.',
                PENDING_AMOUNT: 'Pending Amount',
                TOTAL_AMOUNT_TOOLTIP:
                  'Sum of the amounts of the operations originally recorded for the client.',
                AVERAGE_PAYMENT_TIME: 'Average Payment Time',
                AVERAGE_PAYMENT_TIME_TOOLTIP:
                  'Average time between due date and payment date for the operations associated with the customer.',
                CONTACTABILITY: 'Contactability',
                NO_PAYMENTS: 'No payments',
                CONTACTABILITY_TOOLTIP:
                  'The effectiveness of message delivery by SMS and emails is analyzed to predict whether the contact will receive future messages, with -10 being non-contactable and +10 being high contactability',
              },
              CONTACT: {
                NOTIFICABLE_PHONE: 'This phone number is being notified',
                NOTIFICABLE_EMAIL: 'This email is being notified',
              },
              CASES: {
                TITLE: 'Instances',
                NO_CASES: 'No related records to show',
                NO_OPERATIONS: 'No operations to show',
                TABLE: {
                  HEADERS: {
                    STATUS: 'Status',
                    PORTFOLIO: 'Portfolio',
                    COLLECTOR: 'Collection Profile',
                    TOTAL_AMOUNT: 'Total Amount',
                    PENDING_AMOUNT: 'Pending Amount',
                    CAMPAIGN: 'Campaign',
                    TOOLTIP_CAMPAIGN: 'Last campaign that has impacted this instance',
                  },
                  SUBHEADERS: {
                    STATUS: 'Status',
                    ID_OPERATION: 'Operation ID',
                    TITLE: 'Title',
                    AMOUNT: 'Amount',
                    DUE_DATE: 'Due Date',
                    COLLECTOR: 'Collection Profile',
                    ACTIONS: 'Actions',
                  },
                  TOOLTIP: {
                    PARTIAL_PAYMENT: 'Partial payment',
                  },
                },
              },
              PAYMENTS: {
                TITLE: 'Payments',
                NO_PAYMENTS: 'There are no payments related to this customer.',
                VIEW_OPERATIONS: 'View operations',
                TABLE_HEADERS: {
                  PAYMENT_DATE: 'Payment Date',
                  AMOUNT: 'Amount',
                  METHOD: 'Method',
                  OPERATIONS: 'Operations',
                },
                OPERATIONS: {
                  ATTRIBUTED_AMOUNT: 'Attributed Amount',
                  OPERATION: 'Operation',
                  CONCEPTS: 'Concepts',
                  NO_CONCEPTS: 'No concepts available',
                  NO_OPERATIONS: 'There are no operations for this payment.',
                },
              },
            },
          },
          ACTIVITIES: {
            ACTIVITY_TITLE: 'Activities',
            LABEL: {
              LEGEND: 'Keys',
              TYPE_ACTIVITY: 'Type of activities',
              NO_ACTIVITY: 'There is no activity',
              SUBJECT: 'Subject',
              PRE_HEADER: 'Pre header',
              MESSAGE: 'Message',
              WITH_MESSAGE: 'With message',
              REMINDER: 'a reminder',
              VALID_UNTIL: 'Valid until',
              NOTIFICATION: 'Notification',
              SEND_TO: 'send to',
              FROM: 'from',
              WITH_METHOD_PAYMENT: 'with the method payment',
              HAS_CALLED: 'Has been called',
              SHOW_CALL: 'Show call',
              SHOW_WITH_CLIENT: 'Call with client',
              CALL_INIT: 'Call started',
              CALL_FINISH: 'Call finished',
              THE_TELEPHONE: 'The telephone',
              CATALOG: 'has been listed as',
              BLACKLISTED: 'This number is on the blacklist, cannot be contacted',
              FEEDBACK_PAGE: 'the feedback page',
            },
            LEGENDS_DESCRIPTION: {
              payment_order_created: 'A payment attempt has been initiated',
              payment_order_canceled: 'An error occurred in the process',
              payment_order_captured: 'Payment has been completed',
              feedback_visited: 'The feedback page has been visited',
              feedback_sent: 'Feedback has been submitted',
              paylink_notification_visited: 'The payment page has been visited',
              paylink_notification_received: 'Notification has been received',
              paylink_notification_sent: 'Notification has been sent',
              paylink_notification_read: 'Notification has been read',
              paylink_notification_login_visited: 'Log in completed',
              paylink_notification_login_failed: 'Log in failed',
              outbound_call_hung_up: 'The customer has been called',
              phone_contact_blacklisted: 'Contact number added to the blacklist',
            },
            EVENT_DESCRIPTION: {
              payment_order_created: 'has initiated a payment attempt',
              payment_order_canceled: 'has canceled the payment attempt',
              payment_order_captured: 'has completed a payment',
              paylink_notification_error: 'has encountered an error with the notification',
              paylink_notification_visited:
                ' has visited <b class="text-bluegray-700">payment page</b> ',
              paylink_notification_login_visited: 'has logged in',
              paylink_notification_login_failed: 'has encountered an error during log in',
              paylink_notification_sent: 'has sent a notification from',
              paylink_notification_received: 'has received a notification from',
              paylink_notification_read: 'has read the notification',
              feedback_sent: 'has alleged',
              feedback_visited: 'has visited',
              outbound_call_hung_up: 'has been called',
              api: 'API',
              campaign: 'campaign',
              payment_agreement: 'payment agreement',
              reminder: 'payment link',
            },
            DOCU_NAME: {
              payment_order_created: 'Payment attempt',
              payment_order_canceled: 'Payment attempt canceled',
              payment_order_captured: 'Payment completed',
              paylink_notification_error: 'Error with notification',
              paylink_notification_visited: 'Visited payment page',
              paylink_notification_login_visited: 'Logged in',
              paylink_notification_login_failed: 'Login error',
              paylink_notification_sent: 'Notification sent',
              paylink_notification_received: 'Notification received',
              paylink_notification_read: 'Notification read',
              feedback_sent: 'Has complained',
              feedback_visited: 'Visited feedback',
              outbound_call_hung_up: 'Received a call',
              api: 'API',
              campaign: 'Campaign',
              payment_agreement: 'Payment agreement',
              reminder: 'Payment link',
            },
            PDF: {
              DOWNLOADING: 'Downloading file',
              PROCESSING: 'The file is being processed, please wait a moment',
              CUSTOMER_DATA: 'Customer Data',
              PENDING_AMOUNT: 'Pending Amount',
              ORIGINAL_AMOUNT: 'Original Amount',
              AVERAGE_PAYMENT_TIME: 'Average Payment Time',
              CONTACTABILITY: 'Contactability',
              CUSTOMER_DETAILS: 'Customer Details',
              NAME: 'Name',
              EMAIL: 'Email',
              PHONES: 'Phones',
              NIF: 'NIF',
              LOCATION: 'Location',
              NO_DATA: 'No data',
              DAYS: 'days',
              CLIENT_PAYMENTS: 'Client payments',
              METHOD: 'Method',
              DATE: 'Date',
              TOTAL_AMOUNT: 'Total amount',
              OPERATIONS: 'Operations',
              OPERATION_ID: 'Operation ID',
              ATTRIBUTED_AMOUNT: 'Attributed Amount',
              TITLE: 'Title',
              STATUS: 'Status',
              DUE_DATE: 'Due Date',
              COLLECTOR_PROFILE: 'Collector Profile',
              DESCRIPTION: 'Description',
              NO_PAYMENTS_FOR_CLIENT: 'No payments exist for this client',
              CLIENT_CASES: 'Customer instances',
              CASE_STATUS: 'Status',
              OPERATION_AMOUNT: 'Amount',
              OPERATION_STATUS: 'Status',
              OPERATION_TITLE: 'Title',
              OPERATION_DUE_DATE: 'Due Date',
              NO_CASES_FOR_CLIENT: 'No cases exist for this client',
              CLIENT_ACTIVITIES: 'Customer Activities',
              CATEGORY: 'Category',
              EVENT: 'Event',
              EVENT_DATE: 'Date',
              MESSAGE_SUBJECT: 'Subject',
              MESSAGE_TEMPLATE: 'Template',
              MESSAGE_PREHEADER: 'PreHeader',
              MESSAGE_TEXT: 'Message',
              NOTIFICATION_CHANNEL: 'Notification Channel',
              NO_ACTIVITIES_FOR_CLIENT: 'No activities exist for this client',
            },
            TYPE: {
              NOTIFICATION_AND_VISIT: 'Notifications and visits',
              FEEDBACK: 'Feedback',
              PAYMENT_ORDER: 'Payment',
            },
            TOAST: {
              PDF: 'PDF generated successfully',
              PDF_ERROR: 'Error on PDF generation',
            },
            TOOLTIP: {
              DOWNLOAD_PDF: 'Download PDF ',
            },
          },
        },
      },
      CUSTOMER_JOURNEY: {
        ERRORS: {
          NO_DATA: 'No Data Available',
          SHOULD_CONFIG: 'The Customer Journey information must be configured',
          PHASES: 'Error on period config',
          PHASES_OVERLAP:
            'There are overlapping periods or gaps between periods, please review your configuration',
          CAMPAIGNS_WITH_DAYS_BUT_NO_PHASE:
            'These campaigns have days assigned to a period, but that period does not exist',
        },
        BUTTONS: {
          MIN: 'Minimize',
          MAX: 'Maximize',
          CAMPAIGNS_NO_POSITION: 'Campañas no posicionadas',
        },
        MESSAGES: {
          REDIRECT_CREATE: 'Do you want to redirect to campaign creation?',
          CREATION_PHASE: 'Creating period workflow ',
          CREATION_CAMPAIGN: ' Redirect to create campaign ',
          DAYS: 'Days',
          CAMPAIGN_NAME: 'Campaign Name',
          COLLECTORS_PROFILE: 'Collectors profile',
          SEGMENTS: 'Segments ',
          SELF_CURE_RATE: 'Self-cure rate ',
          RECOVERY_RATE: 'Recovery rate',
          RECOVERY: 'Recovery',
          RECOVERY_RATE_TOOLTIP: 'Average recovery rate over the last 6 months',
          RECOVERY_RATE_PERCENT: 'Recovery rate percentage',
          SEGMENTS_TOOLTIP: 'Segments involved in the campaign',
          PAYMENTS_RESOLVED_PAYMEFY:
            'Total payments resolved via Paymefy divided by the total payments received',
          SEGMENTS_LABEL: 'Segments',
          CAMPAIGN_TYPE: 'Campaign Type ',
          CONTINUOUS: 'Continuous',
          CAMPAIGN_NODES: 'Campaign Nodes',
          CAMPAIGN_NODES_COUNT: 'Nodes Count',
          RECOVERY_RATE_LABEL: 'Recovery Rate',
          OPERATIONS_SENT: 'Notified operations',
          OPERATIONS_PAID: 'Operations Paid',
          CLIENTS_CONTACTED: 'Clients Contacted',
          NOTIFICATIONS_SENT: 'Notifications Sent',
          AVERAGE_6_MONTH: 'Average of the last 6 months',
          PAYMEFY_RECOVERY_TOTAL: 'Total recovered by Paymefy',
          EXTERNAL_RECOVERY_TOTAL: 'Total recovered by other means',
          SHOW_WORKFLOW: 'Show workflow',
          NO_DATA: 'No data',
          LAST_MONTH_OPERATIONS: 'Operations in the last month',
          LAST_MONTH_NOTIFICATIONS: 'Notifications in the last month',
          LAST_MONTH_AVG_PAYMEFY_AMOUNT: 'Average Paymefy amount per customer last month',
          LAST_MONTH_AVG_EXTERNAL_AMOUNT: 'Average external amount per customer last month',
          SHOW: 'Show',
          CANCEL: 'Cancel',
          CREATE_CAMPAIGN_WITH: 'Create campaign with period {{phaseName}}',
        },
      },
      IMPORTS: {
        TITLE: 'Imports',
        BUTTONS: {
          IMPORT: 'Import',
          DOWNLOAD: 'Download template',
        },
        TOOLTIP: {
          DOWNLOAD: 'Base template to easily build your own import file.',
        },
      },
      CALENDAR: {
        TITLE: 'Calendar',
        FORM: {
          COLLECTION_PROFILE: 'COLLECTION PROFILE',
        },
      },
      CONVERSATIONS: {
        TITLE: 'Conversations',
        LABEL: {
          STARTED: 'Conversation started',
          CALL_STARTED: 'Call started',
          CALL_FINISHED: 'Call finished',
          NOTIFICATION_SENT: 'The notification has been sent',
          OF_CAMPAIGN: 'of the campaign',
          THROUGH_CHANNEL: 'through the channel',
          REQUEST_CHECK_OP: 'You have requested the following operations to be reviewed:',
          PAYLINK_SENT: 'A paylink has been sent',
          PAYMENT_PLAN_SENT: 'A payment plan has been sent',
          NOTIFICATION_API_SENT: 'A notification has been sent via API',
          NO_CONVERSATIONS: 'There are no conversations',
        },
      },
      BUSINESS_INSIGHTS: {
        TITLE: 'Business Inisight',
      },
      IMPORT: {
        TITLE: 'Import List',
        ELEMENTS: {},
        PAGES: {
          UPLOAD: {
            TITLE: 'Import File',
          },
          LIST: {
            TITLE: 'Import List',
          },
          IMPORT_LIST: {
            TITLE: 'Import List',
            NEW: 'New Import',
            EDIT: 'Edit Import',
            ELEMENTS: {
              IMPORT: 'Import',
            },
            TABLE: {
              HEADERS: {
                CHARGE_DATE: 'LOAD DATE',
                STATUS: 'STATUS',
                TOTAL_AMOUNT: 'TOTAL AMOUNT',
                CHARGED_AMOUNT: 'CHARGED AMOUNT',
                OP_NUMBER: 'NUMBER OF OPERATIONS',
                CHARGED_OP_NUMBER: 'NUMBER OF CHARGED OPERATIONS',
                FILE_NAME: 'FILE NAME',
                ACTIONS: 'ACTIONS',
                ERRORS: 'ERRORS',
              },
              VALUES: {
                ERRORS_STRING: '<b>"{{errorMessage}}"</b> in column <b>"{{errorColumn}}"</b>',
                NO_ERRORS: 'All records are correct',
                NO_ERROR: 'No errors',
              },
              MORE: {
                NO_RESULTS: 'No imports to show',
              },
            },
          },
          EDIT: {
            TITLE: 'Review Format',
            FILTER: {
              TITLE: 'Filter Format',
              ALL: 'All',
              VALID: 'Correct',
              INVALID: 'Incorrect',
            },
            TABLE: {
              HEADERS: {
                STATUS: 'FORMAT',
                NOMBRE_CORTO: 'SHORT NAME',
                NOMBRE_COMPLETO: 'FULL NAME',
                MOVIL: 'MOBILE',
                EMAIL: 'EMAIL',
                ES_EMPRESA: 'IS COMPANY',
                NIF: 'NIF',
                CODIGO_CLIENTE: 'CUSTOMER CODE',
                CODIGO_POSTAL: 'POSTAL CODE',
                TITULO: 'TITLE',
                CONCEPTO: 'CONCEPT',
                IMPORTE: 'AMOUNT',
                CODIGO_OPERACION: 'OPERATION CODE',
                CODIGO_TRANSACCION: 'TRANSACTION CODE',
                FECHA_LIMITE: 'DEADLINE',
                CODIGO_IMPAGO_DEVOLUCION_SEPA: 'NON-PAYMENT CODE',
                IDIOMA: 'LANGUAGE',
                DIRECCION: 'ADDRESS',
                CIUDAD: 'CITY',
                PAIS: 'COUNTRY',
                ALIAS: 'ALIAS',
                URL_FACTURA: 'INVOICE URL',
                ESTADO: 'STATUS',
              },
              CELL: {
                STATUS: {
                  VALID: 'Correct',
                  INVALID: 'Error',
                },
              },
            },
            MORE: {
              NO_RESULTS: 'No rows to show',
              TIME_LIMIT:
                'Imported rows will remain valid for 5 days, after which the information will be deleted.',
              LOADING_RESULTS: 'Loading rows...',
            },
          },
          END: {
            TITLE: 'Complete Import',
            DESCRIPTION: {
              TITLE: 'Summary',
              OPERATIONS_NUMBER_TITLE: 'Number of operations to import:',
              OPERATIONS_NUMBER: '{{operations}} out of {{operations_total}}',
              TOTAL_IMPORT: 'Total Amount: ',
            },
          },
        },
      },
      OPERATIONS: {
        TITLE: 'Operations',
        ELEMENTS: {},
        COMPONENTS: {
          PAYMENTS: {
            TITLE: 'Payments',
            ON_DATE: 'on',
            PAID_AMOUNT: 'has paid',
            PAID_PARTIAL: 'has paid',
            ATTRIBUTED_AMOUNT: 'of which',
            VIA: 'via',
            CUSTOM_CONCEPT: 'Custom concept',
            ORDER_NUMBER: 'Order number',
            VIEW_OPERATIONS: 'View Operations',
            NO_PAYMENTS: 'There are no registered payments for this operation',
          },
          CONCEPT_CARD: {
            ADD_CONCEPT_HEADER: 'Add concept',
            EDIT_CONCEPT_HEADER: 'Edit concept',
            DELETE_CONCEPT_HEADER: 'Delete concept',
            DELETE_CONFIRMATION_MESSAGE: 'Are you sure you want to delete the concept {{concept}}?',
            DELETE_ACCEPT_LABEL: 'Delete',
            DELETE_REJECT_LABEL: 'No',
            DELETE_ICON: 'pi pi-exclamation-triangle',
            OPERATION_UPDATED: 'Operation {{operationTitle}} Updated',
            ERROR_GENERIC: 'An unexpected error occurred',
            ERROR: 'Error',
            TOTAL_LABEL: 'Total',
            CURRENCY_MODAL_HEADER: 'Currency concept',
            ADD_CONCEPT_BUTTON_LABEL: 'Add concept',
            CONCEPTS_TITLE: 'Concepts',
            TOTAL_AMOUNT: 'Total',
            CHANGE_CONCEPT_BUTTON_TOOLTIP: 'Edit concept',
            DELETE_CONCEPT_BUTTON_TOOLTIP: 'Delete concept',
            CAN_WRITE_ERROR_MESSAGE: 'You cannot edit this operation',
            AMOUNT: 'Amount',
            CONCEPT: 'Concept',
          },
          GENERAL_INFO: {
            ID_OPERATION_LABEL: 'Operation ID',
            COLLECTION_PROFILE_LABEL: 'Collection Profile',
            TITLE_LABEL: 'Title',
            WALLET_LABEL: 'Wallet',
            EXPIRATION_DATE_LABEL: 'Expiration Date',
            DESCRIPTION_LABEL: 'Description',
            CREATION_DATE_LABEL: 'Creation Date',
            MODIFICATION_DATE_LABEL: 'Modification Date',
            ERROR_UPDATE_OPERATION: 'Error updating operation',
            ERROR_UPDATE_OPERATION_MESSAGE: 'Failed to update the operation',
            EDIT: 'Edit',
          },
          SECTIONS: {
            CONCEPTS: 'Concepts',
            PAYMENTS: 'Payments',
            HISTORIC: 'Historic',
            NO_NOTES_MESSAGE: 'No notes for this operation',
            NOTE_DATE_LABEL: 'Date:',
            WRITE_NOTE_PLACEHOLDER: 'Write a note',
            SAVE_BUTTON_LABEL: 'Save',
            CHANGE_STATUS_BUTTON_LABEL: 'Change status',
            EXTERNAL_ID_LABEL: 'Ext. ID',
            COPY_ID_TOOLTIP: 'Copy ID',
            GENERAL_INFORMATION_TAB: 'General Information',
            MORE_INFORMATION_TAB: 'More Information',
            NOTES_TAB: 'Notes',
            EDIT_GENERAL_INFO_TOOLTIP: 'Edit general information',
            EDIT_STATUS_HEADER: 'Edit the status of {{operationTitle}} with ID {{operationId}}',
            EDIT_GENERAL_INFO_HEADER: 'Edit operation information',
            OPERATION_UPDATED_SUCCESS: 'The operation has been successfully updated',
            OPERATION_STATUS_CHANGED_SUCCESS: 'The operation status has been successfully changed',
            OPERATION_STATUS_CHANGED_ERROR: 'There was an error changing the operation status',
            OPERATION_CHARGED_SUCCESS: 'The operation status has been changed to Charged',
            OPERATION_CHARGED_HEADER: 'Operation status modified',
            ERROR_UNEXPECTED: 'An unexpected error occurred. {{errorMessage}}',
            ERROR_GENERIC: 'Please try again later.',
            COPY_TEXT_SUCCESS: 'This text has been copied',
            COPY_TEXT_HEADER: 'Copied',
            ACTION_PERFORMED_SUCCESS: 'Action performed',
            ERROR_TITLE: 'Error',
            COLLECTION_PROFILE: 'Collection Profile',
            PORTFOLIO: 'Portfolio',
            DESCRIPTION: 'Description',
            CREATION_DATE: 'Creation Date',
            MODIFICATION_DATE: 'Modification Date',
            DUE_DATE: 'Due Date',
            KEY_LABEL: 'Key',
            TYPE_LABEL: 'Type',
            VALUE_LABEL: 'Value',
            NO_DATA_MESSAGE: 'No data to display',
          },
          STATUS_CHANGE: {
            FORM: {
              LABEL: 'Change Status',
              STATUS: 'Status',
              MESSAGE: 'Message',
              PAYMENT_DATE: 'Payment Date',
              PAYMENT_METHOD: 'Payment Method',
              PAYER: 'Payer',
            },
          },
          HISTORIC: {
            TITLE: 'Historic',
            LEGEND: 'Legend',
            NO_HISTORIC: 'No history to display for this operation',
            RECEIPT: 'Receipt',
            OPERATION: 'Operation',
            ACTIVITY_TYPE: 'Activity Type',
            HA_ALEGADO: 'has claimed',
            SIN_COMENTARIO: 'No comment',
            ACTIVITIES: {
              PAGO_REGISTRADO_DE: 'Payment registered from',
              POR: 'by',
              OPERATION_CREATED: 'Operation created',
              OPERATION_STATUS_CHANGED: 'Status changed',
              NO_COMMENTS: 'No comments',
              OPERATION_UPDATED: 'Operation updated',
              NOTE_ADDED: 'Note added',
              TITLE_UPDATED: 'Title updated',
              DUE_DATE_UPDATED: 'Due date updated',
              PORTFOLIO_ASSIGNED: 'Portfolio <b>{{alias}}</b> has been assigned to the operation',
              UPDATED_ITEMS: 'Updated items ',
              PAYMENT_ORDER_CANCELED: 'has canceled a payment attempt',
              PAYMENT_ORDER_REFUND_SUCCEEDED: 'Refunded',
              EVENT_NAME: 'Event notification: {{eventName}}',
              ATTACHMENT_VISITED: 'Attachment visited',
              NOTIFICATION_ERROR: 'Notification error',
              NOTIFICATION_RECEIVED: 'Notification received',
              HAS_READ: 'has read the attached document',
              ERROR_NOTIFICATION: 'There was an error with the notification',
              RECIVIED_NOTIFICATION: 'has received a notification',
              EXPIRATION: 'Expiration date',
              VALID_UNTIL: 'Valid until:',
              NOTIFICATION: 'Notification',
              TEMPLATE_NOT_FOUND: 'Template not found',
              ASK_PAYMENT: 'has requested a payment receipt',
              FROM: 'From:',
              SUBJECT: 'Subject',
              PRE_HEADER: 'Pre-header',
              MESSAGE: 'Message',
              RECEIVED: 'Received',
              SENT: 'Sent',
              SENT_TO: 'Sent to',
              READ: 'Read',
              NUMBER_ORDER: 'Number order:',
              GATEWAY_NUMBER: 'Gateway:',
              REFUND: 'has refunded',
              EVENT_NAMES: {
                payment_order_created: 'has started a payment attempt',
                payment_order_canceled: 'has canceled the payment attempt',
                payment_order_captured: 'has paid',
                paylink_notification_error: 'Notification error',
                paylink_notification_visited: 'has visited the payment page, from the notification',
                paylink_notification_login_visited: 'Login',
                paylink_notification_login_failed: 'Login error',
                paylink_notification_sent: 'Notification sent',
                paylink_notification_received: 'has received a notification',
                paylink_notification_read: 'Notification read',
                feedback_sent: 'has submitted feedback',
                feedback_visited: 'has visited the feedback page',
                api: 'API',
                campaign: 'Campaign',
                payment_agreement: 'Payment agreement',
                reminder: 'Reminder',
              },
              LABELS: {
                causation: 'From',
                validUntil: 'Valid until',
                notification: 'Notification',
                sentTo: 'sent to',
                copy: {
                  subject: 'Subject',
                  message: 'Message',
                },
              },
            },
            LEGENDS: {
              OPERATION_CREATED: 'Operation created',
              OPERATION_STATUS_CHANGED: 'Operation status changed',
              OPERATION_UPDATED: 'Operation updated',
              OPERATION_PORTFOLIO_ASSIGNED: 'Portfolio assign to the operation',
              PAYMENT_CREATED: 'Payment made',
              PAYMENT_ORDER_CANCELED: 'Payment canceled',
              PAYMENT_ORDER_REFUND_SUCCEEDED: 'Refund payment successful',
              PAYLINK_NOTIFICATION_SENT: 'Notification sent',
              PAYLINK_NOTIFICATION_VISITED: 'Notification visited',
              PAYLINK_NOTIFICATION_READ: 'Notification read',
              PAYLINK_NOTIFICATION_RECEIVED: 'Notification received',
              PAYLINK_NOTIFICATION_ERROR: 'Notification error',
              PAYLINK_NOTIFICATION_ATTACHMENT_VISITED: 'Document read',
              FEEDBACK_SENT: 'Feedback sent',
              PAYMENT_ORDER_CAPTURED: 'Payment captured',
            },
            INPUT: {
              FEEDBACK: 'Feedback',
              PAYMENT_ORDER: 'Payment Page',
              PAYMENT: 'Payment',
              PAYLINK_NOTIFICATION: 'Notification',
              RECEIPT: 'Receipt',
              OPERATION: 'Operation',
              ACTIVITY_TYPE: 'Activity Type',
            },
          },
        },
        PAGES: {
          SEARCH: {
            COLLECTOR: 'Collection Profile',
            STATUS: 'Status',
            PAYMENT_METHOD: 'Payment Method',
            DUE_DATE: 'Due Date',
            PAYMENT_DATE: 'Payment Date',
            CREATION_DATE: 'Creation Date',
            MODIFICATION_DATE: 'Modification Date',
            SEARCH: {
              LABEL: 'Search',
              TOOLTIP:
                'Search among OPERATION ID, TITLE, CUSTOMER ID, CUSTOMER for similar results.\n\nYou can also search for multiple items separated by commas.\n\nExample: Invoice 22/0001, Invoice 22/0002, Maria Garcia',
            },
          },
          LIST: {
            TITLE: 'Operations List',
          },
          OPERATION_LIST: {
            TITLE: 'Operations List',
            NEW: 'New Operations',
            EDIT: 'Edit Operations',
            TOOLTIP_ALL_SELECTED: "Operations with the 'Paid' status will not be selected",
            FORM: {},
            DELETE_OPERATION_DIALOG: {
              SINGLE_HEADER: 'Delete operation: <b>{{ title }} ({{ externalId }})</b>',
              MULTIPLE_HEADER: 'Delete <b>{{ count }}</b> operations',
              SINGLE_CONFIRM: 'Are you sure you want to delete the operation {{ externalId }}?',
              MULTIPLE_CONFIRM: 'Are you sure you want to delete {{ count }} operations?',
              SUCCESS: {
                SINGLE: '{{ title }} deleted',
                MULTIPLE: '{{ count }} operations deleted',
              },
              ERROR: {
                SINGLE: 'There was an error deleting {{ title }}',
                MULTIPLE: 'There was an error deleting {{ count }} operations',
              },
            },
            CHANGE_STATUS_DIALOG: {
              SINGLE_HEADER: 'Edit operation: <b>{{ title }} ({{ externalId }})</b>',
              MULTIPLE_HEADER: 'Edit operation: <b>{{ count }}</b> operations',
              TOAST: {
                ERROR: 'An error occurred while changing the status of the operations. {{ error }}',
                PAYMENT_SUCCESS: 'The payment has been created successfully',
                STATUS_CHANGED_SUCCESS:
                  '{{ count }} operations changed their status to {{ status }}',
                STATUS_CHANGED_FAILED:
                  'An error occurred while changing the status of {{ count }} operations',
              },
              UNEXPECTED_ERROR: {
                SUMMARY: 'Unexpected Error',
                DETAIL: 'An unexpected error occurred. {{ error }}',
              },
            },
            TABLE: {
              DELETE_OP: 'Delete {{selectedOperations}}',
              CHANGE_STATUS_OP: 'Change status {{selectedOperations}}',
              DOWNLOAD: 'Download',
              DOWNLOAD_PROCESS: 'Download {{process}} %',
              HEADERS: {
                STATUS: 'STATUS',
                COLLECTOR: 'COLLECTOR PROFILE',
                OPERATION_ID: 'OPERATION ID',
                TITLE: 'TITLE',
                DUE_DATE: 'DUE DATE',
                CUSTOMER_ID: 'CUSTOMER ID',
                CUSTOMER_NAME: 'CUSTOMER NAME',
                TOTAL_AMOUNT: 'TOTAL AMOUNT',
                PENDING_AMOUNT: 'PENDING AMOUNT',
                PAYMENT_DATE: 'PAYMENT DATE',
                PAYMENT_METHOD: 'PAYMENT METHOD',
                CREATION_DATE: 'CREATION DATE',
                UPDATE_DATE: 'UPDATE DATE',
                ACTIONS: 'ACTIONS',
              },
              MORE: {
                NO_RESULTS: 'No operations to display',
              },
            },
          },
          PENDING_AMOUNT: 'Pending Amount',
          PENDING_AMOUNT_TOOLTIP: 'Pending amount of the invoice',
          NO_PENDING_PAYMENTS: '✔ No pending payments',
          ORIGINAL_AMOUNT: 'Original Amount',
          ORIGINAL_AMOUNT_TOOLTIP:
            'Total of the amount of the operation originally recorded for the client',
          DUE_DATE: 'Due Date',
          DUE_DATE_TOOLTIP: 'Due date of the operation',
          CREATION_DATE: 'Creation Date',
          CREATION_DATE_TOOLTIP: 'Creation date of the invoice in Paymefy',
          OPERATIONS_FILE: {
            TITLE: 'Operation Details',
          },
        },
      },
      SEGMENTS: {
        TITLE: 'Segments',
        ELEMENTS: {},
        PAGES: {
          SEGMENT_LIST: {
            TITLE: 'List of segments',
            NEW: 'New segments',
            CREATE_NEW: 'Crate a new segment',
            EDIT: 'Edit segments',
            INFO_SEGMENTS: 'Segments information',
            ERROR: 'Error',
            ERROR_CREATING: 'There was an error creating the segment.',
            ERROR_UPDATING: 'There was an error updating the segment.',
            ERROR_DELETING: 'There was an error deleting {{name}}.',
            SUCCESS: 'Success',
            SEGMENT_CREATED: 'The segment {{name}} was created successfully.',
            SEGMENT_UPDATED: 'The segment {{name}} was updated successfully.',
            SEGMENT_DELETED: 'The segment {{name}} was deleted successfully.',
            DELETE: 'Delete Segment',
            DELETE_CONFIRMATION: 'Are you sure you want to delete {{name}}?',
            TOOLTIP_IN_USE: 'Segment is in use in campaigns.',
            VIEW: 'View Segment',
            DIALOG: `You cannot <b>delete</b> segments that are already <b>in use</b> in a campaign. To delete a segment, you must first archive the campaign that uses it.`,
            FORM: {
              NAME: 'Name',
            },
            TABLE: {
              HEADERS: {
                NAME: 'Name',
                ACTIONS: 'Actions',
              },
              ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
              },
              MORE: {
                NO_RESULTS: 'There are no segments to show',
              },
            },
          },
        },
      },
      LAYOUT: {
        ACTIVITY: {
          TITLE: 'Activity Summary',
          LABEL: {
            ACTIVITIES: 'Activities',
          },
          OPTIONS: {
            PAYLINK_NOTIFICATION_SENT: 'Notifications',
            PAYMENT_ORDER_CAPTURED: 'Payments',
            FEEDBACK_SENT: 'Feedbacks',
          },
          MESSAGES: {
            API: 'You have sent a notification for {{customerName}} ({{externalId}}) from {{source}}',
            CAMPAIGN:
              'You have sent a notification to {{customerName}} ({{externalId}}) from {{source}}',
            PAYMENT_AGREEMENT:
              'You have sent a payment agreement to {{customerName}} ({{externalId}})',
            REMINDER: 'You have sent a payment link to {{customerName}} ({{externalId}})',
            DEFAULT: 'You have sent a notification to {{customerName}} ({{externalId}})',
            PAYMENT_RECEIVED:
              'You have received a payment of {{amount}} from {{customerName}} ({{externalId}})',
            FROM: 'from {{source}}',
            FEEDBACK: '{{customerName}} ({{externalId}}) give you feedback',
          },
        },
      },
      SHARED: {
        COMPONENTS: {
          SEND_REMINDER: {
            TITLE: 'Send Reminder',
            DESCRIPTION: 'Send Reminder',
            PHONE_SEND_MSG: 'You will send a reminder to the following phone: ',
            EMAIL_SEND_MSG: 'You will send a reminder to the following email address: ',
            MONTH: 'month',
            MONTHS: 'months',
            PAYMENT_PROPOSAL_EXPIRATION:
              'The payment agreement proposal will expire <b>{{ hours }} hours</b>after being sent.',
            DESCRIPTION_MSG:
              'You will send a pre-approved payment agreement to <b>{{ customerName }}</b> for a total amount of <b>{{ totalAmount }}</b>.',
            FIRST_PAYMENT:
              'An initial charge of <b>{{ firstPayment }}</b> will applied at the time of the first payment, and the remaining installments will be automatically charged monthly for <b>{{ nMonths }} {{ monthText }}</b> to the payment channel <b>{{ sms }} {{ email }}</b>.',
            EXACT_DATES:
              'Before making the first payment, the customer can view the exact dates of each charge',
            INSTALLMENTS_TABLE_HEADER: {
              INSTALLMENT: 'Installment',
              AMOUNT: 'Amount',
            },
            FORM: {
              ACTIONS: {
                SEND: 'Send',
                CONFIRM: 'Confirm',
                CONFIRM_MESSAGE: 'Do you want to send the reminder?',
              },
              CHANNEL: {
                LABEL: 'Select Channels',
              },
            },
          },
          SEND_PAYMENT_AGREEMENT: {
            TITLE: 'Send Payment Agreement',
            DESCRIPTION: 'Send Payment Agreement',
            PHONE_SEND_MSG: 'You will send a payment agreement to the phone: ',
            EMAIL_SEND_MSG: 'You will send a payment agreement to the email: ',
            SUCCESS: 'Payment agreement sent successfully',
            ERROR: 'Error sending the payment agreement',
            HEADER_SUCCESS: 'Action Completed',
            HEADER_ERROR: 'Error',
            FORM: {
              ACTIONS: {
                SEND: 'Send',
                CONFIRM: 'Confirm',
                CONFIRM_MESSAGE: 'Do you want to send the payment agreement?',
              },
              CHANNEL: {
                LABEL: 'Select Channels',
                INSTALMENTS: 'Number of Installments',
              },
            },
          },
          PARTIAL_PAYMENT: {
            SMS: 'SMS',
            EMAIL: 'Email',
            MINIMUM_AMOUNT: 'Minimum Amount',
            MINIMUM_PERCENTAGE: 'Minimum Amount %',
            SUCCESS_TITLE: 'Partial Payment Offer Sent',
            SUCCESS_MESSAGE: 'The offer has been sent successfully!',
            ERROR_TITLE: 'Error',
            ERROR_MESSAGE:
              'Failed to send the partial payment offer. It might have already been sent.',
            TITLE: 'Partial Payment Configuration',
            SEND_BTN: 'Send Partial Payment',
            TOTAL_DEBT: 'owes a total of',
            SEND_PROPOSAL_MINIMUM:
              'you will send a partial payment proposal requiring a minimum payment of',
            SEND_PROPOSAL_PERCENTAGE:
              'you will send a partial payment proposal with a minimum amount of',
            EXPIRES_72H: 'which will expire 72 hours from the time it is sent.',
            FORM: {
              MIN_AMOUNT: 'Minimum Amount',
              MIN_AMOUNT_PERCENT: 'Minimum Amount in %',
              CHANNEL: 'Channel',
              TEMPLATE: 'Template',
            },
          },
        },
      },
      PROFILE: {
        TITLE: 'Personal Information',
        DESCRIPTION: 'Update your personal information',
        INFO: 'Personal data',
        INFO_UPDATE: 'Update your personal data',
        FORM: {
          NAME: 'NAME',
          SURNAME: 'LAST NAME',
          EMAIL: 'EMAIL',
          ROLE: 'ROLE',
          LANGUAGE: 'LANGUAGE',
        },
        TOAST: {
          SUCCESS: 'User Updated',
          ERROR: 'Error updating user',
          ERROR_HEADER: 'Error',
          SUCCESS_HEADER: 'Action Completed',
        },
      },
      LISTING: {
        BC: {
          NOTIFICATIONS: 'Notifications sent',
          CONTACT_ERRORS: 'Contact errors',
          ABANDONED_PAYMENTS: 'Abandoned payment gateway',
          PAYMENTS: 'Payment list',
          BLOCKED_PHONES: 'Blocked mobiles and non-notifiable phone numbers for SMS',
          OP_NOTIFIED: 'Notified operations',
        },
        TOOLTIP: {
          CONTACT_ERRORS:
            'The selected date range corresponds to the date of the first notification delivery attempt.',
          ABANDONED_PAYMENTS:
            'The selected date range corresponds to the date and time when the payment button was clicked for payments that were not completed.',
          NOTIFICATION:
            'The selected date range corresponds to the date the notification was sent.',
          PAYMENTS:
            'The selected date range reflects the date and time when the payment was confirmed by the payment gateway.',
          BLOCKEDPHONES:
            'The selected date range corresponds to the period when mobile numbers and phones are not reachable.',
        },
        TYPES: {
          ABANDONED_PAYMENTS: 'Abandoned_Payments_',
          NOTIFICATION: 'Notifications_',
          CONTACT_ERRORS: 'Contact_Errors_',
          PAYMENTS: 'Payments_',
        },
        LABELS: {
          ABANDONED_PAYMENT: 'Abandoned Payment Gateway',
          NOTIFICATION: 'Notifications',
          CONTACT_ERROR: 'Contact Errors',
          PAYMENTS: 'Payments',
        },
        TITLE: 'Listings',
        GENERATE: {
          TITLE: 'Generate a Listing',
          GENERATE: 'Generate Listing',
          FORM: {
            COLLECTOR: 'COLLECTOR PROFILE',
            TIME_RANGE: 'TIME RANGE',
          },
        },
        TYPE: {
          ABANDONED_PAYMENT: {
            TITLE: 'Generated Listings',
            LIST: {
              TABLE: {
                NO_RESULTS: 'No abandoned payment gateways to display',
                HEADERS: {
                  ID: 'ID',
                  NAME: 'LIST NAME',
                  DATE_CREATED: 'CREATION DATE',
                  TIME_RANGE: 'TIME RANGE',
                  N_RESULTS: 'RESULTS',
                  STATUS: 'STATUS',
                  COLLECTORS: 'COLLECTOR PROFILES',
                  ACTIONS: 'ACTIONS',
                },
              },
            },
          },
          NOTIFICATION_LIST: {
            TITLE: 'Generated Listings',
            LIST: {
              TABLE: {
                NO_RESULTS: 'No notifications to display',
                HEADERS: {
                  ID: 'ID',
                  NAME: 'LIST NAME',
                  DATE_CREATED: 'CREATION DATE',
                  TIME_RANGE: 'TIME RANGE',
                  N_RESULTS: 'RESULTS',
                  STATUS: 'STATUS',
                  COLLECTORS: 'COLLECTOR PROFILES',
                  ACTIONS: 'ACTIONS',
                },
              },
            },
          },
          CONTACT_ERROR: {
            TITLE: 'Generated Listings',
            LIST: {
              TABLE: {
                NO_RESULTS: 'No contact errors to display',
                HEADERS: {
                  ID: 'ID',
                  NAME: 'LIST NAME',
                  DATE_CREATED: 'CREATION DATE',
                  TIME_RANGE: 'TIME RANGE',
                  N_RESULTS: 'RESULTS',
                  STATUS: 'STATUS',
                  COLLECTORS: 'COLLECTOR PROFILES',
                  ACTIONS: 'ACTIONS',
                },
              },
            },
          },
        },
      },
      INSTANCES: {
        TITLE: 'Instances',
        CONFIG: {
          OPEN: 'The case still has pending payments and may receive notifications by email, SMS, or calls.',
          CLOSED: 'The case has no pending payments and stops receiving any kind of notification.',
          SILENCED: 'Silenced case',
        },
        MASSIVE_SELECTION: {
          SELECTED: {
            ASSIGN_PILL: 'Assign Pill',
            SELECTED_COUNT: 'You have selected {{countOp}} operations grouped in {{count}} cases ',
            FILTERS: 'Filters',
            COLLECTORS_COUNT: 'Collectors: {{count}} num.',
            CAMPAIGNS_COUNT: 'Campaigns: {{count}} num.',
            STATUS: 'Status',
            NO_FILTERS: 'No filters applied',
            AMOUNT_RANGE: 'PENDING AMOUNT: {{min}} to {{max}} {{currency}}',
            MORE_ITEMS: '+{{count}} more',
            STATUS_ACTIVE: 'Active',
            STATUS_INACTIVE: 'Inactive',
            SINGLE: 'You have selected',
            OPERATIONS: 'operations',
            GROUPED_IN: 'grouped in',
            ALL_SELECTED: 'Select all {{ totalResults }} instances',
            SEND_MASSIVE_PILL: 'Assign pills massively',
            ARE_YOU_SURE:
              'Are you sure you want to proceed with this action? Make sure the following data is correct:',
          },
          MASSIVE_SELECTED: {
            DELETE: 'Remove massive selection',
          },
        },
        FORM: {
          LABELS: {
            COLLECTOR_IDS: 'Collection Profiles',
            STATUSES: 'Instance Status',
            WORKFLOW_IDS: 'Campaigns',
            HAS_PASSED: 'Have you been through any campaigns?',
            IS_WORKED: 'Is in campaign?',
            MIN_PENDING_AMOUNT: 'Minimum Pending Amount',
            MAX_PENDING_AMOUNT: 'Maximum Pending Amount',
            PENDING_AMOUNT: 'Pending amount',
            SEARCH: 'Search',
            SEGMENT_ID: 'Segments',
          },
          PLACEHOLDERS: {
            SELECT_OPTION: 'Select an option',
          },
        },
        ELEMENTS: {
          STATUS: {
            ACTIVE: 'Active',
          },
        },
        LIST: {
          FORM: {
            SEARCH_TOOLTIP: `Search by External Operation ID.

      You can also search for multiple items separated by commas.

          Example: Op1234, Op1235, Op1236`,
          },
          TITLE: 'Instances List',
          TABLE: {
            NO_RESULTS: 'No cases to display',
            NO_OPERATIONS: 'No operations to display',
            HEADERS: {
              STATUS: 'STATUS',
              CUSTOMER_NAME: 'CUSTOMER NAME',
              CUSTOMER: 'CUSTOMER ID',
              COLLECTOR: 'COLLECTOR PROFILE',
              PORTFOLIO: 'PORTFOLIO',
              CAMPAIGN: 'CAMPAIGN',
              N_OPERATIONS: 'Nº OF OPERATIONS',
              TOTAL_AMOUNT: 'TOTAL AMOUNT',
              PENDING_AMOUNT: 'PENDING AMOUNT',
              DATE: 'CREATION DATE',
              ACTIONS: 'ACTIONS',
              SUBHEADERS: {
                STATUS: 'STATUS',
                ID_OPERATION: 'OPERATION ID',
                TITLE: 'TITLE',
                AMOUNT: 'AMOUNT',
                DUE_DATE: 'DUE DATE',
              },
            },
          },
        },
      },
      REPORTS: {
        TITLE: 'Reports',
        BC: {
          PANEL: 'Panel',
          CLIENTS: 'Clients',
          CAMPAIGNS: 'Campaigns',
          COLLECTION: 'Collection by period',
          COLLECTORS: 'Collection by profiles',
          WORKFLOWS: 'Collection by month',
          PORTFOLIOS: 'Global Portfolio',
        },
        FILTERS: {
          CAMPAIGNS: 'CAMPAIGNS',
          COLLECTORS: 'COLLECTION PROFILES',
          PORTFOLIOS: 'PORTFOLIOS',
          DATE: 'DATE',
          DATE_NOTIFICATION: 'DATE OF NOTIFICATION',
          DATE_COLLECTION_CREATED: 'DEBT CREATION MONTH',
          PERIOD: 'COLLECTION PERIOD (IN MONTHS)',
          CAMPAIGN_PAYMENTS: 'RECOVERY BY CAMPAIGN',
          STATUS_OPERATION_IGNORE: 'IGNORE OPERATION STATUS',
        },
        PAGES: {
          ROOT: {
            DESCRIPTIONS: {
              PANEL: 'Tenant Reports',
              CLIENTS: 'Client Reports',
              CAMPAIGNS: 'Campaign Reports',
              COLLECTORS: 'Collection Profile Reports',
              COLLECTION: 'Collection by Period Reports',
              WORKFLOWS: 'Collection by Month Reports',
              PORTFOLIOS: 'Global Portfolio Reports',
            },
          },
        },
      },
      PAYMENT_AGREEMENT: {
        TITLE: 'Payment Agreements',
        ELEMENTS: {
          DOWNLOAD: {
            LABEL: 'Download',
          },
        },
        FORM: {
          PROFILE_OF_COLLECTION: 'Collection Profile',
          STATUS: 'Status',
          SEARCH: 'Search',
          SELECT_PROFILE: 'Select Profile',
          SELECT_STATUS: 'Select Status',
          ENTER_SEARCH_PATTERN: 'Enter search pattern',
          BROKEN: 'Broken',
          FULFILLED: 'Fulfilled',
          EXPIRED: 'Expired',
          PROPOSAL: 'Proposal',
          SIGNED: 'Signed',
        },
        PAGES: {
          LIST: {
            TABLE: {
              HEADERS: {
                COLLECTOR: 'COLLECTION PROFILE',
                CHARGE_DATE: 'START DATE',
                STATUS: 'STATUS',
                TOTAL_AMOUNT: 'TOTAL AMOUNT',
                CHARGED_AMOUNT: 'CHARGED AMOUNT',
                OP_NUMBER: 'NUMBER OF OPERATIONS',
                CHARGED_OP_NUMBER: 'PAID INSTALLMENTS',
                FILE_NAME: 'COLLECTOR',
                EXTERNAL_CLIENT_CODE: 'EXTERNAL CLIENT CODE',
                CLIENT: 'CLIENT',
                TITLE: 'TITLE',
              },
              MORE: {
                NO_RESULTS: 'No payment agreements to display',
              },
            },
          },
        },
      },
      CAMPAINGS: {
        TITLE: 'Campaigns',
        CAMPAIGN: 'Campaign',
        CAMPAIGN_NAME: 'Campaign Name',
        CAMPAIGN_TYPE: 'Campaign Type',
        NO_ACTIONS: 'No actions availables',
        CALENDAR: 'Calendar',
        CAMPAIGN_GENERATOR: 'Campaign builder',
        ERROR: {
          TITLE: 'Error',
          UNKNOWN: 'An unknown error occurred',
          ID_NOT_FOUND: 'Campaign ID not found',
          UPDATE_FAILED: 'Failed to update/create the campaign',
        },
        PROGRESS: {
          TITLE: 'Progress',
          CREATING: 'Creating the campaign...',
        },
        SUCCESS: {
          TITLE: 'Success',
          CREATED: 'Campaign created successfully',
        },
        STEPPER: {
          CAMPAIGN: 'Campaign',
          SEGMENTATION: 'Segmentation',
          STRATEGY: 'Strategy',
          CHECK: 'Check',
        },
        WORKFLOWS: {
          WAIT: {
            INFO_WAIT_DAYS_FOR_DUE_DATE:
              'Notifications configured below will be sent in <b>{{ partDay }}</b>, <b>{{ waitDays }}</b> days <b>{{ beforeOrAfter }}</b> the due date of the invoice. If the customer has more than one invoice, the date of the oldest invoice due date is prioritized.',
            INFO_WAIT_DAYS:
              'Notifications configured below will be sent in <b>{{ partDay }}</b>, within <b>{{ waitDays }}</b> days.',
            INFO_WAIT_DAY_OF_WEEK:
              'Notifications configured below will be sent in <b>{{ partDay }}</b>, on <b>{{ dayOfWeek }}</b>.',
            INFO_WAIT_DAY_OF_MONTH:
              'Notifications configured below will be sent in <b>{{ partDay }}</b>, on day <b>{{ dayOfMonth }}</b> of this month.',
          },
          EDIT_WORKFLOW: 'Edit workflow',
          SAVE_CHANGES: 'Save changes',
          SAVE_CHANGES_WITH_ADVERT: 'Save changes with warnings',
          BEFORE_SAVE: 'Before saving, keep in mind that:',
          THERE_IS_ERRORS: `There is errors on the node's settings`,
          ARE_YOU_SURE: 'Are you sure you want to save the changes like this?',
          CANCEL: 'Cancel',
          HAS_TO_SOLVE: 'The following errors must be resolved:',
          NODE_FORM: {
            TIME_SLOT: 'Delivery Time Slot',
            TIME_SLOT_MORNING: 'Morning (9:00 AM - 2:00 PM)',
            TIME_SLOT_EVENING: 'Evening (2:00 PM - 8:00 PM)',
            TIME_SLOT_FULL_DAY: 'Full Day (9:00 AM - 8:00 PM)',
            WAIT_DAYS: 'Days',
            WAIT_DAYS_PLACEHOLDER: 'Enter wait days',
            DAY_OF_WEEK: 'Day of the Week',
            SELECT_OPTION: 'Select an option',
            DAY_OF_MONTH: 'Day of the month',
            TEMPLATES: 'Templates',
            MESSAGE: 'Messages',
            VOICE: 'Voice',
            STATUS: 'Status',
            SEGMENTS: 'Segments',
            PERCENTAGE: 'Percentage of the experiments',
            MINIMUM_AMOUNT: 'Minimum amount',
            MINIMUM_AMOUNT_TAN: 'Minimum amount on %',
            SEND_ALL_CONTACTS:
              'Send this message to all available channel addresses for each client',
            RECEIVED: 'Received',
            ASSIGN_CAMPAIGN: 'Assign to campaign',
            BEFORE_AFTER: 'Before or after expiration',
            NUMBER_FEE: 'Number of installments',
            DURATION_DAYS: 'Duration in days',
            EXPIRATION: 'Expiration',
            BEFORE: 'Before',
            AFTER: 'After',
            EXPIRATION_DAYS: 'Expiration Days',
            READ: 'Read',
            ERROR: 'Error',
            DAYS: {
              MONDAY: 'Monday',
              TUESDAY: 'Tuesday',
              WEDNESDAY: 'Wednesday',
              THURSDAY: 'Thursday',
              FRIDAY: 'Friday',
              SATURDAY: 'Saturday',
              SUNDAY: 'Sunday',
            },
          },
          NODE_TYPE: {
            WAITS: 'Wait',
            NOTIFICATIONS: 'Send',
            CONDITIONAL: 'Split',
            FLEX_OPTIONS: 'Flex payments',
            CALL_OPTIONS: 'Call',
            OFFER_PAYMENT_AGREEMENT_LINE1: 'Payment',
            OFFER_PAYMENT_AGREEMENT_LINE2: 'agreement',
            OFFER_PARTIAL_LINE_1: 'Partial',
            OFFER_PARTIAL_LINE_2: 'payment',
            END: 'End',
          },
          CALENDAR: {
            EVENT_TITLE: 'Title',
            EVENT_DESCRIPTION: 'Description',
            EVENT_START_DATE: 'Start Date',
            EVENT_END_DATE: 'End Date',
            EVENT_TYPE: 'Type',
            NEW_EVENT: 'New Event',
            EDIT_EVENT: 'Edit Event',
            DISPLAY_EVENT: 'Event',
            DESCRIPTION_LABEL: 'Description',
            START_DATE_LABEL: 'Start',
            END_DATE_LABEL: 'End',
            TYPE_LABEL: 'Type',
          },
          VALIDATIONS: {
            INVALID_LENGTH_OF_NODES: 'There must be at least 3 nodes in the diagram',
            NOT_CONNECTED_TO_END: 'There is a branch in the diagram not connected to a final node',
            INVALID_NUMBER_OF_START_NODES: 'Incorrect number of start nodes',
            NO_END_NODE: 'No end nodes exist',
            NO_PARENT_NODE: 'Event nodes do not have a parent node connected',
            NO_CHILD_NODE: 'Event nodes do not have a child node connected',
            DUPLICATE_NODE_IDS: 'Duplicate nodes exist',
            NO_COPY_IDS: 'Templates must be selected for node {{nodoType}}',
            NO_TARGET_IDS: 'A target campaign must be selected for node {{nodoType}}',
            NO_TARGET_NODE: 'A target node must be selected for node {{nodoType}}',
            NO_STATUS: 'At least one status must be selected for node {{nodoType}}',
            NO_SEGMENTS: 'At least one segment must be selected for node {{nodoType}}',
            NO_MIN_AMOUNT: 'A minimum amount must be added for node {{nodoType}}',
            EXCEEDED_PERCENTAGE: 'Percentage must be between 1 and 99',
            EXCEEDED_CADUCITY: 'Expiration must be between 1 and 90 days',
            EXCEEDED_INSTALMENTS: 'Number of instalments must be between 2 and 11',
            NO_EXPIRATION: 'An expiration day must be added for node {{nodoType}}',
            NO_PART_DAY: 'Wait days must be added for node {{nodoType}}',
            NO_DAY_OF_MONTH: 'A day of the month must be selected for node {{nodoType}}',
            NO_WAIT_DAYS: 'Wait days must be added for node {{nodoType}}',
            NO_BEFORE_AFTER: 'Before or after expiration must be selected for node {{nodoType}}',
            ERROR_DAY_BEFORE_AFTER:
              'There is an error in the days configured related to the Before or After field. The value might be incorrect for node {{nodoType}}',
            NO_DAY_OF_WEEK: 'A day of the week must be selected for node {{nodoType}}',
            NO_CASE_CONDITIONAL:
              'A true or false case must be selected and configured for node {{nodoType}}',
            NO_VOICE_ID: 'A voice code must be added for node {{nodoType}}',
          },
        },
        SEGMENTS: {
          CONDITIONS: {
            FIELD: 'Field {{field}} with value: {{value}}',
            CUSTOM_FIELD: 'Custom Field',
            CUSTOMER_CASE_OVERDUE_DAYS: 'Debt Age',
            PORTFOLIO_IDS: 'Portfolios',
            CUSTOMER_AGE: 'Customer Age',
            CUSTOMER_GENDER: 'Gender',
            CUSTOMER_CASE_PENDING_AMOUNT: 'Pending Amount',
            COLLECTOR_IDS: 'Collection Profiles',
            CUSTOMER_IS_COMPANY: 'Ownership',
            AGE: 'years old',
            OVERDUE_DAYS: 'overdue days',
            FROM_TO: 'From {{from}} to {{to}} {{key}}',
            OR: 'OR',
            FROM: 'From ',
            UNTIL: 'Until ',
            VALUE_NOT_VALID: 'Value not valid',
            BUSINESS: 'Company',
            PRIVATE: 'Private',
            FILTER_NOT_VALID: 'Filter not valid',
            NUMB_OPERATIONS: 'Importe ',
          },
          SELECT_CONDITION: 'Select a condition',
          NO_SEGMENTS_WIZARD:
            'You have not selected any specific segment. This means that ALL NEW INSTANCES from the selected payment profiles [list of payment profiles selected in the previous step] could be included in this campaign. Are you sure you want to continue?',
          NO_CONDITION: 'No condition in this segmentation',
          CONDITION_NAME_NOT_FOUND: 'Condition name not found for the specified key.',
          MODAL: {
            HEADER: 'Create a new segment',
            SAVE: 'Save changes',
            LOADING: 'Loading...',
            ADD_NEW: 'Add new',
            SAVE_SEGMENTS: 'Save segment',
            INCLUDE_SEGMENTS: 'Include segment',
            COLLECTOR_PROFILE: 'Collector Profile',
            PORTFOLIO: 'Portfolio',
            MIN_PENDING_AMOUNT: 'Min Pending Amount',
            MAX_PENDING_AMOUNT: 'Max Pending Amount',
            MIN_OVERDUE_DAYS: 'Min Overdue Days',
            MAX_OVERDUE_DAYS: 'Max Overdue Days',
            MIN_PAYABLE_OPERATIONS: 'Min Payable Operations',
            MAX_PAYABLE_OPERATIONS: 'Max Payable Operations',
            MIN_AGE: 'Min Age',
            MAX_AGE: 'Max Age',
            OWNERSHIP: 'Ownership',
            GENDER: 'Gender',
            THE_SEGMENT: 'The segment: ',
            NAME_SEGMENT: `Segment's name`,
            VALUE: 'Value',
            CUSTOM_FIELD: 'has the custom field {{field}} with value {{value}}',
            COLLECTOR_IDS: 'has the collector profiles: {{collectors}}',
            PORTFOLIO_IDS: 'the selected portfolios are: {{portfolios}}',
            CUSTOMER_CASE_PENDING_AMOUNT:
              'the pending amount is between {{minAmount}} {{minCurrency}} and {{maxAmount}} {{maxCurrency}}',
            CUSTOMER_CASE_OVERDUE_DAYS:
              'the debt age is between {{minDays}} days and {{maxDays}} days',
            CUSTOMER_CASE_NUMBER_OF_PAYABLE_OPERATIONS:
              'the number of operations is between {{minOperations}} and {{maxOperations}}',
            CUSTOMER_AGE: 'the customer age is between {{minAge}} years and {{maxAge}} years',
            CUSTOMER_IS_COMPANY: 'the owner is {{ownership}}',
            CUSTOMER_GENDER: 'the gender is {{genders}}',
            DEFAULT: '{{type}}: {{value}}',
            CONDITIONAL: 'AND',
            START_FILLING: 'Start filling the form to see the information her',

            VAR_FIELD: 'Variable field',
            INFO_TOOLTIP_VAR_FIELD: `Placeholder type field defined by the client's domain in the import file`,
            VALIDATION: {
              MAX_FIELD_REQUIRED: 'The maximum field is required if the minimum field has a value.',
              MIN_FIELD_REQUIRED: 'The minimum field is required if the maximum field has a value.',
              SAME_VALUE_NOT_ALLOWED: 'The values cannot be the same.',
              MIN_GREATER_THAN_MAX: 'The minimum value cannot be greater than the maximum value.',
              MAX_LESS_THAN_MIN: 'The maximum value cannot be less than the minimum value.',
              BOTH_FIELDS_FILLED: 'Both fields must be filled',
            },
          },
          TOAST: {
            ERROR: {
              TITLE: 'Error',
              MESSAGE: 'Please review the segment information',
            },
            SUCCESS: {
              TITLE: 'Segment created',
              MESSAGE: 'The segment {{name}} has been created successfully',
            },
          },
          SUCCESS: {
            TITLE: 'Campaign Updated',
            MESSAGE: 'The campaign has been successfully updated.',
          },
          ERROR: {
            TITLE: 'Update Failed',
            MESSAGE: 'The campaign could not be updated.',
          },
          EDIT: {
            HEADER: 'Edit Campaign Information',
          },
          SELECT_SEGMENT: 'Select at least one segment',
          NO_OPTIONS: 'No segmentation options available',
          LOADING_SEGMENTS: 'Loading segments',
          EDIT_SEGMENTS: 'Edit segments',
        },
        TYPE: {
          PILL: 'Pill',
          DRIP: 'Continuous',
        },
        GENERIC_INFO: {
          NO_NAME: 'No name available',
          EDIT_INFORMATION: 'Edit information',
          NO_TRIGGER: 'No triggers',
          START_DAYS: '{{days}} days from start',
          FINISH_DAYS: '{{days}} days',
          NO_PHASE: 'No period',
          EDIT_CAMPAIGN_INFORMATION: 'Edit campaign information',
          ENTER_CRITERIA: {
            SAVE_CRITERIA: 'Save enter criteria',
            TOOLTIP_EDIT: 'Edit enter criteria',
            PAYMENT_PROFILES: 'Payment profiles',
            NO_PAYMENT_PROFILES: 'No payment profiles available.',
            ACTIVE_CASES: 'Active cases: {{caseCount}}',
            OR: 'Or,',
            ADDITIONALLY: 'Additionally',
            SEGMENTS: 'Segments',
            NO_SEGMENTS: 'No segments available.',
            FILTERS: 'Filters: {{filterCount}}',
            CONDITION_NAME: '{{conditionName}}',
            CONDITION_VALUE: '{{conditionValue}}',
            VIEW_COLLECTOR: 'View another collectors',
            HIDDE_COLLECTOR: 'Hide collectors',
            HIDDE_SEGMENTS: 'Hide segmentations',
            VIEW_SEGMENTS: 'View another segmentations',
            ACTIVE_PROFILES: 'Active Collection Profiles',
            ACTIVE_PROFILES_TOOLTIP:
              'The cases that meet the entry criteria of these collection profiles will be automatically added to the campaign and registered as active cases.',
            DISCARDED_PROFILES: 'Discarded Collection Profiles',
            DISCARDED_PROFILES_TOOLTIP:
              'No new cases will be added to these collection profiles. They will remain visible as long as there are active cases in the campaign and will automatically be hidden when all cases are completed.',
          },
          ENTER_CRITERION: 'Campaign entry criteria',
          INPUTS: {
            NUMBER_NODES: 'Number of nodes',
            STATUS: 'Status',
            TYPE: 'Campaign type',
            NAME: {
              LABEL: 'Campaign Name',
              PLACEHOLDER: 'Enter the campaign name',
            },
            PHASES: {
              LABEL: 'Phase',
              PLACEHOLDER: 'Select a period',
            },
            COLLECTORS: {
              LABEL: 'Collector',
              PLACEHOLDER: 'Select a collector profile',
            },
            START: {
              LABEL: 'Phase Start',
              ADDON: 'days',
            },
            FINISH: {
              LABEL: 'Phase End',
              ADDON: 'days',
            },
            TRIGGERS: {
              LABEL: 'Behavior',
              PLACEHOLDER: 'Select an event',
            },
          },
          ERRORS: {
            PHASE_START_TOO_EARLY:
              'The Start value cannot be earlier than the earliest allowed start for the configured periods.',
            PHASE_END_TOO_LATE:
              'The End value cannot be later than the latest allowed end for the configured periods.',
            SAME_PHASE_VALUE: 'The values cannot be the same.',
            RANGE_INVALID: 'The Start value cannot be greater than the End value.',
          },
          BUTTONS: {
            SAVE: 'Save changes',
          },
          TYPE: {
            PILL: 'Pill type campaign',
            CONTINUOUS: 'Ongoing campaign',
            PILL_SIMPLE: 'Pill',
            DRIP_SIMPLE: 'Continuous',
          },
          TOAST: {
            SUCCESS: {
              UPDATED: {
                TITLE: 'Campaign updated',
                MESSAGE: 'The campaign has been successfully updated',
              },
            },
            ERROR: {
              UPDATED: {
                TITLE: 'Campaign update error',
                MESSAGE: 'The campaign could not be updated',
              },
            },
          },
          MODAL: {
            HEADER: 'Edit campaign information',
          },
        },
        STATUS: {
          ACTIVE: 'active',
          PAUSED: 'paused',
          DRAFT: 'draft',
          PUBLISHED: 'published',
          UNPUBLISHED: 'archived',
          NO_WORKFLOW: 'Reminder or direct link',
        },
        NEW: 'New Campaign',
        CLONE: {
          ACTIONS: {
            CLONE: 'Clone',
          },
          FORM: {
            NAME: 'NEW NAME',
          },
        },
        ELEMENTS: {
          NODES: {
            ACTIVE_CASES: {
              N_CASES: 'Active cases: {{count}}',
              NO_CASES: 'No active cases',
            },
            TYPES: {
              EVENT: 'Event',
              ACTIVITY: 'Activity',
              CONDITION: 'Condition',
            },
            NAMING: {
              waitDays: 'Wait Days',
              waitDayOfWeek: 'Wait for weekday',
              waitDaysForDueDate: 'Wait until Due Date',
              waitDayOfMonth: 'Wait for day of month',
              sendSMS: 'SMS',
              sendCertifiedSMS: 'Certified SMS',
              sendEmail: 'Email',
              sendCertifiedEmail: 'Certified Email',
              superSendSMS: 'Send Super SMS',
              superSendCertifiedSMS: 'Send Super Certified SMS',
              superSendEmail: 'Send Super Email',
              superSendCertifiedEmail: 'Send Super Certified Email',
              paylinkNotificationStatus: 'Yes (notification)',
              customerCaseSegments: 'Yes (segment)',
              offerPartialPayment: 'Partial Payment',
              offerPaymentAgreement: 'Payment Agreement',
              assignCampaign: 'Assign to Campaign',
              makeOutboundCall: 'Outbound Call',
              experiment: 'Experiment',
              start: 'Start',
              end: 'End',
              email_plus: 'Email +',
              email_plus_certified: 'Certified Email +',
              sms_plus_certified: 'Certified SMS  +',
              sms_plus: 'SMS  +',
            },
            PARAMETERS: {
              TARGET_NODE: 'Target Node',
              SELECT_NODE: 'Select a target node',
              SUBJECT: 'Subject',
              PRE_HEADER: 'Pre Header',
              MESSAGE: 'Message',
              BUTTON_TEXT: 'Button Text',
              COPY_MESSAGE: 'Copy Message',
              PARTIAL_PAYMENT_PROPOSAL: 'A payment agreement proposal will be sent with ',
              EXPIRATION_DAYS: 'It will expire in',
              NUMBER_OF_INSTALLMENTS: 'A payment agreement proposal will be sent with',
              INSTALLMENTS: 'installments',
              CUSTOMER_PAYMENT_DATES:
                'The customer will see the exact dates of each charge before the first payment.',
              CONFIGURATION: 'Configuration',
              EXPERIMENT:
                '<b>{{percentage}}%</b> of the case will follow the branch ✔ for the experiment, while the rest will follow branch ✘',
              SELECT_TARGET_NODE: 'Select a target node',
              NODE_OBJECTIVE: 'NODE OBJECTIVE',
              FROM_NOW: 'from the time of sending',
              DAYS_UNTIL: 'Days until expiration:',
              NUMBER_INSTALMENTS: 'Number of instalments:',
              INSTALMETS_IN: 'instalments in',
              MINIMUM_AMOUNT: 'Minimum amount:',
              MINIMUM_PERCENTAGE: 'Minimum precentage:',
              EXACT_DAY:
                'The customer will be able to see the exact dates of each installment before making the first payment',
              FROM_DAY: `after being sent. The first installment will be charged at the time of the initial payment, and the remaining installments will be automatically charged <b>once per month for</b>`,
              FEE: 'installments',
              EMAIL: {
                SUBJECT: 'Subject',
                PRE_HEADER: 'Pre Header',
                MESSAGE: 'Message',
              },
              SMS: {
                MESSAGE: 'Message',
              },
              OFFER_PARTIAL_PAYMENT: {
                INFO: `A partial payment proposal will be sent for a minimum value of
                      <b>{{amount}}</b> EUR. It will expire in <b>{{days}}</b> days from the time of sending.`,
              },
              OFFER_PAYMENT_AGREEMENT: {
                INFO: `A payment agreement proposal will be sent with
                      <b>{{instalments}}</b> instalments. It will expire
                      <b>{{days}}</b> {{days === 1 ? 'day' : 'days'}} after being sent.
                      The first installment will be charged at the time of the initial payment,
                      and the remaining instalment will be automatically charged <b> once per month for
                      {{instalments}}</b> {{instalments === 1 ? 'month' : 'months'}}.
                      The customer will be able to see th exact dates of each installment before making the first payment.`,
              },
              PART_DAY: {
                OF: 'Sending during: ',
                MORNING: 'Morning',
                EVENING: 'Night',
                FULL_DAY: 'All Day',
              },
              KEYS: {
                name: 'Name',
                waitDays: 'Waiting Days',
                partDay: 'Part of the Day',
                dayOfWeek: 'Day of the Week',
                dayOfMonth: 'Day of the Month',
                daysForDueDate: 'Days to Due Date',
                copyIds: 'Copy IDs',
                copyId: 'Copy ID',
                targetNodeId: 'Target Node ID',
                statuses: 'Statuses',
              },
              NAME: {
                start: 'Start',
                end: 'End',
                waitDays: 'Waiting Days: ',
                waitDayOfWeek: 'Day of the Week: ',
                waitDayOfMonth: 'Day of the Month: ',
                waitDaysForDueDate: 'Days to Due Date: ',
                sendSMS: 'Send SMS',
                sendEmail: 'Send Email',
                sendCertifiedSMS: 'Send Certified SMS',
                sendCertifiedEmail: 'Send Certified Email',
                superSendSMS: 'Send Super SMS',
                superSendEmail: 'Send Super Email',
                superSendCertifiedSMS: 'Send Super Certified SMS',
                superSendCertifiedEmail: 'Send Super Certified Email',
                paylinkNotificationStatus: 'Notification Status',
                assignCampaign: 'Assign Campaign',
              },
            },
          },
        },
        LIST: {
          TITLE: 'Campaign List',
          NEW: 'New Campaign',
          EDIT: 'Edit Campaign',
          TABLE: {
            NO_CAMPAIGNS_MESSAGE: 'There are currently no campaigns',
            TYPES: {
              ACTIVE: 'Active',
              DRAFT: 'Draft',
              UNPUBLISHED: 'Archived',
              PILLS: 'Pills',
            },
            TOOLTIPS: {
              SHOW_COLLECTORS: 'View Collection Profiles',
            },
            HEADERS_PILLS: {
              NAME: 'PILL NAME',
              PILLS_SEND: 'PILLS SENT',
              COLLECTORS: 'COLLECTION PROFILE',
              ACTIVE_CASES: 'ACTIVE CASES',
              CUSTOMER_CASES_COUNT: 'ACTIVE RECORDS',
              ACTIONS: 'ACTIONS',
              SUBHEADERS: {
                CUSTOMER_CASES_NOTIFIED: 'NOTIFIED RECORDS',
                TOOLTIPS: {
                  CREATED: 'Instances are being created. This process may take a few minutes.',
                },
                STATUS: 'STATUS',
                TIME: 'DATE AND TIME',
                CLIENT_NAME: 'CLIENT NAME',
                N_INSTANCES: 'NUMBER OF RECORDS',
              },
            },
            HEADERS: {
              PRIORITY: 'PRIORITY',
              CAMPAIGN_NAME: 'CAMPAIGN NAME',
              COLLECTORS: 'COLLECTION PROFILE',
              SEGMENTS: 'SEGMENTS',
              ACTIVE_CASES: 'ACTIVE RECORDS',
              STATUS: 'STATUS',
              CUSTOMER_CASES_COUNT: 'ACTIVE RECORDS',
              ACTIONS: 'ACTIONS',
            },
            MORE: {
              PRIORITY_TOOLTIP:
                'If several continuous campaigns share segments, priority will decide which campaign the new records are assigned to. If the campaigns do not share segments, priority has no effect.',
              NO_RESULTS: 'No campaigns to show',
              NO_PILLS_RESULTS: 'No pills to show',
              NO_PILLS_SUBTABLE_RESULTS: 'No instances to show',
            },
          },
        },
        WIZARD: {
          GENERAL_DATA: 'General Data',
          CAMPAIGN_NAMING: 'Campaign Name',
          COLLECTORS: 'Collectors',
          CAMPAIGN_TYPE: 'Campaign Type',
          PURPOSE: 'Purpose',
          SUCCESS: 'Success',
          TRIGGERS: 'Behavior',
          PHASE_ACTIONS: 'Phase Actions',
          PHASE_ACTIONS_DESCRIPTION: '{{phaseStart}} days to {{phaseEnd}} days',
          SEGMENTATION: 'Segmentation',
          NODES: 'Nodes',
          NOT_APPLICABLE: 'Not applicable',
          WARNINGS_BEFORE_SAVING: 'Before saving, please note:',
          NODE_CONFIGURATION_ERRORS: 'There are errors in the node configuration',
          SURE_TO_SAVE_CHANGES: 'Are you sure you want to save the changes like this?',
          ERRORS_TO_PUBLISH: 'You need to fix the following errors to publish the campaign:',
          ERROR_LIST: 'There are the following errors:',
          SAVE_DRAFT: 'Save Draft',
          SAVE_CHANGES_WARNING: 'Save changes with warning',
          NAME: 'Campaign Name',
          CAMPAIGN_NAME: 'Enter campaign name',
          PROFILE: 'Collector Profile',
          ACTION_EVENT: 'Action Event',
          SELECT_EVENT: 'Select an event',
          PHASE: 'Phase',
          CREATE_NEW_SEGMENT: 'Create new segment',
          OR: 'OR',
          SEGMENTS_SELECTED: 'Segments selected',
          INFO_SEGMENTS:
            'A record will only be included if it meets all the criteria of at least one of these segments.It is not necessary to meet more than one segment to be part of the campaign.',
          CREATED_SEGMENT_CORRECTLY: 'The segment {{name}}} has been created successfully',
          CREATED_SEGMENT: 'Segment created',
          LOADING_SEGMENTS: 'Loading segments',
          INCLUDE: 'Include:',
          SELECT_OR_CREATE_SEGMENT: 'Select a segment or create a new one.',
          CONTINUE: 'Continue',
          SELECT_PHASE: 'Select a period',
          PHASE_START: 'Phase Start',
          PHASE_END: 'Phase End',
          BEHAVIOR: 'Behavior',
          ACTION: {
            BACK: 'Back',
            NEXT: 'Next',
          },
          ERRORS: {
            PHASE_START_TOO_EARLY:
              'The start value cannot be earlier than the earliest allowed period start.',
            PHASE_END_TOO_LATE: 'The end value cannot be later than the latest allowed period end.',
            SAME_PHASE_VALUE: 'The values cannot be the same.',
            RANGE_INVALID: 'The start value cannot be greater than the end value.',
          },
        },
        MONITORING: {
          TITLE: 'Share Data',
          //...
        },
        EDIT: {
          SUCCESS: 'Success!',
          PRIORITY_CHANGED: 'The order has changed',
          CAMPAIGN_STATE_CHANGED: 'The state has changed to state',
          ERROR: 'Error',
          PAUSED: 'Paused',
          PUBLISHED: 'Published',
          UNPUBLISHED: 'Unpublished',
          CAMPAIGN_UPDATED: 'Campaign updated',
          CAMPAIGN_BEEN_UPDATED: 'Campaign has been updated',
          ERROR_SHOWN: 'An error occurred while updating the campaign',
          RESUME_COLLECTOR_ERROR: 'Could not resume the collector',
          RESUME_COLLECTOR_SUCCESS: 'The collector was resumed successfully',
          PAUSE_COLLECTOR_ERROR: 'Could not pause the collector',
          PAUSE_COLLECTOR_SUCCESS: 'The collector was paused successfully',
          UNPUBLISH_COLLECTOR_ERROR: 'Could not archive the collector',
          UNPUBLISH_COLLECTOR_SUCCESS: 'The collector was archived successfully',
          TITLE: 'Edit Campaign',
          DELETE_DRAFT_MESSAGE: 'Are you sure you want to delete the draft?',
          DELETE_DRAFT_HEADER: 'Delete draft {{campaignName}}',
          DELETE_DRAFT_ACCEPT_LABEL: 'Delete',
          DELETE_DRAFT_ACCEPT_ICON: 'pi pi-trash',
          DELETE_DRAFT_ACCEPT_BUTTON_CLASS: 'p-button-danger',
          DELETE_DRAFT_REJECT_BUTTON_CLASS: 'p-button-text',
          DELETE_DRAFT_SUCCESS_MESSAGE: 'Campaign {{campaignName}} successfully deleted',
          CLONE_CAMPAIGN_MESSAGE: 'Campaign {{campaignName}} cloned',
          CLONE_CAMPAIGN_HEADER: 'Do you want to clone the campaign "{{campaignName}}"?',
          CLONE_CAMPAIGN_SUCCESS_MESSAGE: 'Campaign {{campaignName}} cloned successfully',
        },
        COPIES: {
          TITLE: 'Templates',
          ELEMENTS: {},
          ADD: {
            TITLE: 'Create Template',
            //...
          },
          EDIT: {
            TITLE: 'Edit Template',
            TEMPLATE_UPDATED: '{{name}} has been edited successfully',
            SUCCESS_MESSAGE: 'Success!',
            SAVE_TEMPLATE: 'Save Template',
            SAVE_AUTOTEMPLATE: 'Save Template',
            QUESTION_EDIT:
              'Are you sure you want to save the template? Remember that this template will send {{number}} SMS to each client.',
          },
        },
        OPERATIONS: {
          TITLE: 'Operations',
          ELEMENTS: {},
          COMPONENTS: {
            STATUS_CHANGE: {
              FORM: {
                LABEL: 'Change Status',
              },
            },
          },
          PAGES: {
            LIST: {
              TITLE: 'Operation List',
            },
            OPERATION_LIST: {
              TITLE: 'Operation List',
              NEW: 'New Operation',
              EDIT: 'Edit Operation',
              FORM: {},
              TABLE: {
                HEADERS: {
                  STATUS: 'STATUS',
                  COLLECTOR: 'COLLECTION PROFILE',
                  OPERATION_ID: 'OPERATION ID',
                  TITLE: 'TITLE',
                  DUE_DATE: 'DUE DATE',
                  CUSTOMER_ID: 'CUSTOMER ID',
                  CUSTOMER_NAME: 'CUSTOMER NAME',
                  TOTAL_AMOUNT: 'TOTAL AMOUNT',
                  PENDING_AMOUNT: 'PENDING AMOUNT',
                  PAYMENT_DATE: 'PAYMENT DATE',
                  PAYMENT_METHOD: 'PAYMENT METHOD',
                  CREATION_DATE: 'CREATION DATE',
                  UPDATE_DATE: 'UPDATE DATE',
                  ACTIONS: 'ACTIONS',
                },
                MORE: {
                  NO_RESULTS: 'No operations to show',
                },
              },
            },
            OPERATIONS_FILE: {
              TITLE: 'Operation Details',
            },
          },
        },
        PAYMENT_METHOD: {
          FORM: {
            INPUT: {
              PAYMENT_METHOD: 'Payment Method',
            },
          },
          ADD: {
            TITLE: 'New Payment Method',
            BUTTON: {
              BACK: 'Back',
            },
          },
          EDIT: {
            TITLE: 'Edit Payment Method',
            ENABLED: 'Enabled',
            BUTTON: {
              BACK: 'Back',
            },
          },
          LIST: {
            TITLE: 'Payment Methods',
            BUTTON: {
              ADD: 'New Payment Method',
            },
            INPUT: {
              SEARCH: 'Search',
            },
            TABLE: {
              HEADER: {
                NAME: 'Name',
                DESCRIPTION: 'Description',
                TYPE: 'Type',
                STATUS: 'Status',
                ACTIONS: 'Actions',
                COLLECTORS: 'Associated Collectors',
              },
              VALUES: {
                ENABLED: 'Active',
                DISABLED: 'Inactive',
              },
              BUTTON: {
                EDIT: 'Edit',
                DELETE: 'Delete',
              },
            },
          },
          REDSYS: {
            FORM: {
              INPUT: {
                NAME: 'Name',
                DESCRIPTION: 'Description',
                TYPE: 'Type',
                STATUS: 'Status',
                ACTIONS: 'Actions',
                MERCHANT_CODE: 'Merchant Code',
                MERCHANT_TERMINAL_ID: 'Merchant Terminal ID',
                MERCHANT_SECRET: 'Merchant Secret',
                CUSTOM_CONCEPT: 'Custom Concept',
                TEXT: {
                  MESSAGE: 'Use these tags in the "Display Text" field to customize your messages:',
                  LABELS: {
                    NAME: 'Long Name',
                    CLIENT_ID: 'Client ID',
                    NIF: 'NIF',
                    TITLE: 'Title',
                    OPERATION_ID: 'Operation ID',
                    PERSONALIZADO: '$PERSONALIZADO$',
                  },
                  INFO: `
            <section>
              <p>Additional Details on Custom Payment Concepts</p>
              <p>When setting up custom payment concepts, consider the following:</p>
              <ul>
                <li>If you use the optional variable <code>$PERSONALIZADO$</code>, ensure this information is included in Paymefy. Otherwise, it will not appear in the concept.</li>
                <li>For multi-invoice scenarios, all codes will appear separated by commas (<code>,</code>).</li>
                <li>Default concept: <strong>title</strong>.</li>
                <li>Character limit: <strong>125</strong>. Excess characters will be truncated.</li>
                <li>Allowed characters:
                  <ul>
                    <li>Characters from <code>a</code> to <code>z</code> (avoid special characters or uppercase).</li>
                    <li>Numbers from <code>0</code> to <code>9</code>.</li>
                  </ul>
                </li>
              </ul>
            </section>`,
                  EXTRA: {
                    BIZUM: 'Bizum (Redsys)',
                  },
                },
              },
              BUTTON: {
                CANCEL: 'Cancel',
                SAVE: 'Save',
              },
            },
          },
          INESPAY: {
            FORM: {
              INPUT: {
                NAME: 'Name',
                DESCRIPTION: 'Description',
                COMPANY_TYPE: 'Type',
                ACTIONS: 'Actions',
                IBAN: 'IBAN',
                API_KEY: 'API Key',
                API_TOKEN: 'API Token',
                CUSTOM_CONCEPT: 'Custom Concept',
                TEXT: {
                  MESSAGE: 'Use these tags in the "Display Text" field to customize your messages:',
                  LABELS: {
                    NAME: 'Long Name',
                    CLIENT_ID: 'Client ID',
                    NIF: 'NIF',
                    TITLE: 'Title',
                    OPERATION_ID: 'Operation ID',
                    PERSONALIZADO: '$PERSONALIZADO$',
                  },
                  INFO: `
            <section>
              <p>Updated Information on Custom Payment Concepts</p>
              <p>Follow these guidelines for configuring custom payment concepts in Paymefy:</p>
              <ul>
                <li>If you use <strong>optional variables</strong>, ensure this information is included in Paymefy. Otherwise, it will not appear in the concept.</li>
                <li>Default concept: <em>origin account IBAN, external_id, long name</em>.</li>
                <li>For multi-invoice scenarios, all codes will appear separated by commas (<code>,</code>).</li>
                <li>Character limit: <strong>100</strong>. Excess characters will be truncated.</li>
                <li>Allowed characters:
                  <ul>
                    <li>Characters from <code>a</code> to <code>z</code>.</li>
                    <li>Numbers from <code>0</code> to <code>9</code>.</li>
                    <li>Additional allowed characters: <code>+</code>, <code>?</code>, <code>/</code>, <code>-</code>, <code>:</code>, <code>(</code>, <code>)</code>, <code>.</code>, <code>,</code>, <code>'</code>.</li>
                  </ul>
                </li>
              </ul>
            </section>
          `,
                },
              },
              BUTTON: {
                CANCEL: 'Cancel',
                SAVE: 'Save',
              },
            },
          },
          STRIPE: {
            FORM: {
              INPUT: {
                NAME: 'Name',
                DESCRIPTION: 'Description',
                COMPANY_TYPE: 'Type',
                ACTIONS: 'Actions',
                SECRET_KEY: 'Secret key',
                PUBLISHABLE_KEY: 'Publishable key',
                WEB_HOOK_SECRET: 'Web hook secret',
                CUSTOM_CONCEPT: 'Custom concept',
                TEXT: {
                  MESSAGE:
                    'Use these tags in the "Text to display" field to customize your messages:',
                  LABELS: {
                    NAME: 'Full name',
                    CLIENT_ID: 'Client ID',
                    NIF: 'Tax ID',
                    TITLE: 'Title',
                    OPERATION_ID: 'Operation ID',
                    PERSONALIZADO: '$CUSTOM$',
                  },
                  INFO: `<section>
          <p>Information about Custom Payment Concepts</p>
          <p>Custom payment concepts must comply with the following guidelines:</p>
          <ul>
            <li>They must contain <strong>only Latin characters</strong>.</li>
            <li>The length must be <strong>between 5 and 22 characters</strong>, inclusive.</li>
            <li>They must contain <strong>at least one letter</strong>. If using a prefix and suffix, both must include at least one letter.</li>
            <li><strong>They must not contain</strong> any of the following special characters: <code>&lt;</code>, <code>&gt;</code>, <code>V</code>, <code>*</code>, <code>"</code>.</li>
          </ul>
        </section>`,
                },
              },
              BUTTON: {
                CANCEL: 'Cancel',
                SAVE: 'Save',
              },
            },
          },
          PAYCOMET: {
            FORM: {
              INPUT: {
                NAME: 'Name',
                DESCRIPTION: 'Description',
                COMPANY_TYPE: 'Type',
                ACTIONS: 'Actions',
                IBAN: 'IBAN',
                SWIFT_CODE: 'SWIFT Code',
                CUSTOM_CONCEPT: 'Custom concept',
                TEXT: {
                  MESSAGE:
                    'Use these tags in the "Text to display" field to customize your messages:',
                  LABELS: {
                    NAME: 'Full name',
                    CLIENT_ID: 'Client ID',
                    NIF: 'Tax ID',
                    TITLE: 'Title',
                    OPERATION_ID: 'Operation ID',
                    PERSONALIZADO: '$CUSTOM$',
                  },
                  INFO: `<section>
          <p>Instructions for Custom Payment Concepts</p>
          <p>When configuring custom payment concepts in Paymefy, note the following guidelines:</p>
          <ul>
            <li>For <strong>optional variables</strong>, the corresponding information must be included in Paymefy. Otherwise, it will not appear in the payment concept.</li>
            <li>The <strong>default concept</strong> includes: source account IBAN, external_id, full name.</li>
            <li>In <strong>multi-invoice</strong> cases, all codes will be listed separated by commas (<code>,</code>).</li>
            <li>There is a <strong>character limit of 100</strong>. If the concept exceeds this limit, it will be truncated.</li>
            <li><strong>Allowed characters</strong> include:
              <ul>
                <li>Alphabetical characters from <code>a</code> to <code>z</code>.</li>
                <li>Numbers from <code>0</code> to <code>9</code>.</li>
                <li>Additionally, the following special characters are allowed: <code>+</code>, <code>?</code>, <code>/</code>, <code>-</code>, <code>:</code>, <code>(</code>, <code>)</code>, <code>.</code>, <code>,</code>, <code>'</code>.</li>
              </ul>
            </li>
          </ul>
        </section>`,
                },
              },
              BUTTON: {
                CANCEL: 'Cancel',
                SAVE: 'Save',
              },
            },
          },
          INGENICO: {
            FORM: {
              INPUT: {
                NAME: 'Name',
                DESCRIPTION: 'Description',
                PSPID: 'PSPID',
                INTEGRATOR: 'Integrator',
                API_KEY: 'API Key',
                API_SECRET: 'API Secret',
                WEBHOOKS_KEY: 'Webhooks Key',
                WEBHOOKS_KEY_SECRET: 'Webhooks Key Secret',
                CUSTOM_CONCEPT: 'Custom concept',
                TEXT: {
                  MESSAGE: "Use these labels in the 'Display Text' to customize your messages:",
                  LABELS: {
                    NAME: 'Full name',
                    CLIENT_ID: 'Client ID',
                    NIF: 'NIF',
                    TITLE: 'Title',
                    OPERATION_ID: 'Operation ID',
                    PERSONALIZADO: '$CUSTOMIZED$',
                  },
                  INFO: '',
                },
              },
              BUTTON: {
                CANCEL: 'Cancel',
                SAVE: 'Save',
              },
            },
          },
          MYMOID: {
            FORM: {
              INPUT: {
                NAME: 'Name',
                DESCRIPTION: 'Description',
                APP_ID: 'App ID',
                APP_SECRET: 'App Secret',
                CUSTOM_CONCEPT: 'Custom concept',
                TEXT: {
                  MESSAGE: "Use these labels in the 'Display Text' to customize your messages:",
                  LABELS: {
                    NAME: 'Full name',
                    CLIENT_ID: 'Client ID',
                    NIF: 'NIF',
                    TITLE: 'Title',
                    OPERATION_ID: 'Operation ID',
                    PERSONALIZADO: '$CUSTOMIZED$',
                  },
                  INFO: '<section> <p>Configuration for Custom Payment Concepts</p> <p>When defining custom payment concepts for use in Paymefy, consider the following guidelines:</p> <ul> <li>When using the optional variable <code>$CUSTOMIZED$</code>, ensure this information is included in Paymefy. Otherwise, it will not appear in the concept.</li> <li>For <strong>multi-invoice</strong> cases, all codes will be displayed separated by commas (<code>,</code>).</li> <li><strong>Default concept:</strong> There is no default concept.</li> <li>The <strong>character limit</strong> is <strong>150</strong>. If exceeded, the concept will be truncated.</li> <li><strong>Mymoid</strong> does not impose restrictions on characters used in payment concepts.</li> </ul> </section>',
                },
              },
              BUTTON: {
                CANCEL: 'Cancel',
                SAVE: 'Save',
              },
            },
          },
          MONETICO: {
            FORM: {
              INPUT: {
                NAME: 'Name',
                DESCRIPTION: 'Description',
                SOCIETE: 'Company name',
                TPE: 'TPE No.',
                KEY: 'Key',
                DEFAULT_ADDRESS: 'Address',
                DEFAULT_CITY: 'City',
                DEFAULT_POSTAL_CODE: 'Postal code',
                DEFAULT_COUNTRY: 'Country code',
                TEXT: {
                  MESSAGE: "Use these labels in the 'Display Text' to customize your messages:",
                  LABELS: {
                    NAME: 'Full name',
                    CLIENT_ID: 'Client ID',
                    NIF: 'NIF',
                    TITLE: 'Title',
                    OPERATION_ID: 'Operation ID',
                    PERSONALIZADO: '$CUSTOMIZED$',
                  },
                  INFO: '',
                },
              },
              BUTTON: {
                CANCEL: 'Cancel',
                SAVE: 'Save',
              },
            },
          },
          MONEI: {
            FORM: {
              INPUT: {
                NAME: 'Name',
                DESCRIPTION: 'Description',
                TYPE: 'Type',
                STATUS: 'Status',
                ACTIONS: 'Actions',
                MERCHANT_CODE: 'Merchant code',
                MERCHANT_TERMINAL_ID: 'Merchant terminal ID',
                MERCHANT_SECRET: 'Merchant secret',
                CUSTOM_CONCEPT: 'Custom concept',
                TEXT: {
                  MESSAGE: "Use these labels in the 'Display Text' to customize your messages:",
                  LABELS: {
                    NAME: 'Full name',
                    CLIENT_ID: 'Client ID',
                    NIF: 'NIF',
                    TITLE: 'Title',
                    OPERATION_ID: 'Operation ID',
                    PERSONALIZADO: '$CUSTOMIZED$',
                  },
                },
              },
              BUTTON: {
                CANCEL: 'Cancel',
                SAVE: 'Save',
              },
            },
          },
          GREENPAY: {
            FORM: {
              INPUT: {
                NAME: 'Name',
                DESCRIPTION: 'Description',
                API_KEY: 'API Key',
                ACCOUNT_ID: 'Account ID',
                CUSTOM_CONCEPT: 'Custom concept',
                TEXT: {
                  MESSAGE: "Use these labels in the 'Display Text' to customize your messages:",
                  LABELS: {
                    NAME: 'Full name',
                    CLIENT_ID: 'Client ID',
                    NIF: 'NIF',
                    TITLE: 'Title',
                    OPERATION_ID: 'Operation ID',
                    PERSONALIZADO: '$CUSTOMIZED$',
                  },
                  INFO: '',
                },
              },
              BUTTON: {
                CANCEL: 'Cancel',
                SAVE: 'Save',
              },
            },
          },
        },
      },
    },
  },
};
